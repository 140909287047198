import L, { Point } from "leaflet";
import { LatLng, LatLngExpression } from "leaflet";
export const flipLatLng = (
	latLng: any,
	scale: boolean = false,
	scaleType: "up" | "down" = "up"
): LatLngExpression => {
	let flipped = [latLng[1], latLng[0]];
	if (scale) {
		const corrected = correctProjection(L.latLng(latLng[0], latLng[1]));
		return upScaleLatLng(corrected) as LatLngExpression;
	}
	return flipped as LatLngExpression;
};

export const flipPoly = (
	coordinates: any,
	scale: boolean = false,
	scaleType: "up" | "down" = "up"
) => {
	if (coordinates[0] && coordinates[0] instanceof Array) {
		return coordinates.map((row: any) => flipPoly(row, scale, scaleType));
	}

	return flipLatLng(coordinates, scale, scaleType);
};

const scaleNumber = (min: number, max: number, a: number, b: number, x: number) => {
	let top = (b - a) * (x - min);
	let bottom = max - min;
	return top / bottom + a;
};

export const upScaleLatLng = (latlng: any) => {
	let lat = latlng[0];
	let lng = latlng[1];
	let min = 0;
	let max = 12;
	let a = 0;
	let b = 4096;

	lat = scaleNumber(min, max, a, b, lat);
	lng = scaleNumber(min, max, a, b, lng);
	return [lat, lng];
};

export const downScaleLatLng = (latlng: any) => {
	let lat = latlng[0];
	let lng = latlng[1];
	let min = 0;
	let max = 4096;
	let a = 0;
	let b = 12;

	lat = scaleNumber(max, min, a, b, lat);
	lng = scaleNumber(min, max, a, b, lng);
	return [lat, lng];
};

var mapSize = 12;
var mapMaxZoom = 4;

var tileExtent = [0.0, -1.0 * mapSize, 1.0 * mapSize, 0.0];

var mapMaxResolution = 1.0;
var mapMinResolution = Math.pow(2, mapMaxZoom) * mapMaxResolution;

const correctProjection = (latlng: LatLng): LatLngExpression => {
	let newLatLng = L.latLng(latlng.lng - 0.025, latlng.lat);
	return [12 - newLatLng.lat, newLatLng.lng];
};

const project = function (latlng: LatLng) {
	return new Point(latlng.lng, latlng.lat);
};
const unproject = function (point: Point) {
	return new LatLng(point.y, point.x);
};

export var tilesCrs = L.extend(L.CRS.Simple, {
	transformation: L.transformation(1, tileExtent[0], 1, tileExtent[3]) as any,
	scale: function (zoom: number) {
		return Math.pow(2, zoom) / mapMinResolution;
	},
	zoom: function (scale: number) {
		return Math.log(scale * mapMinResolution) / Math.LN2;
	},

	projection: {
		project: project,
		unproject: unproject,
	},
});

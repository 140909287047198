import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMaps } from "../../../services/api";
import { STATUS } from "../../../types";
import { GroupedMap } from "../../../types/MapTypes";


type MapsStateType = {
    status: STATUS;
    list: GroupedMap[]
};

const initialState:MapsStateType = {
    status: STATUS.IDLE,
    list: [],
}

const fetchMaps = createAsyncThunk(
    "map/maps/fetch",
    async () => {
        const response = await getMaps();
        return response;
    });



const mapsSlice = createSlice({
    name: "map/maps",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMaps.pending, (state) => {
            state.status = STATUS.PENDING;
        })
        builder.addCase(fetchMaps.fulfilled, (state, action) => {
            state.status = STATUS.SUCCESS;
            state.list = action.payload;
        });

        builder.addCase(fetchMaps.rejected, (state) => {
            state.status = STATUS.ERROR;
        });
    }
});

export const MapsActions = {fetchMaps , ...mapsSlice.actions}
export default mapsSlice.reducer;
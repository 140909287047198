import L, { DomEvent, Util } from "leaflet";
import * as PIXI from "pixi.js";

export const WebCanvas = L.Canvas.extend({
	// Initialize pixi

	options: {
		padding: 0,
	},

	_textures: [],

	_initContainer: function () {
		let app = new PIXI.Application({ width: 100, height: 100, backgroundAlpha: 0 });

		let container = (this._container = app.view);
		container.style.zIndex = "101";

		DomEvent.on(container, "mousemove", this._onMouseMove, this);
		DomEvent.on(container, "click dblclick pointerdown pointerup contextmenu", this._onClick, this);
		DomEvent.on(container, "mouseout", this._handleMouseOut, this);
		// @ts-ignore
		container["_leaflet_disable_events"] = true;
		this._app = app;
		this._ctx = app.stage;
	},

	_update: async function () {
		if (this._map._animatingZoom && this._bounds) {
			return;
		}

		await new Promise((resolve) => setTimeout(resolve, 100));

		// @ts-ignore
		this._preUpdate();

		var b = this._bounds,
			container = this._container,
			size = b.getSize(),
			m = L.Browser.retina ? 1 : 1;

		L.DomUtil.setPosition(container, b.min);

		// set canvas size (also clearing it); use double size on retina
		container.width = m * size.x;
		container.height = m * size.y;
		container.style.width = size.x + "px";
		container.style.height = size.y + "px";

		if (L.Browser.retina) {
			// this._ctx.scale(2, 2);
		}

		this._app.renderer.resize(size.x, size.y);

		// Can be used to scale the canvas on zoom;
		// this._app.stage.width = 10;
		// this._app.stage.height = 10;

		// translate so we use the same path coordinates after canvas element moves
		// this._ctx.translate(-b.min.x, -b.min.y);

		// Tell paths to redraw themselves
		this._updateStage();
		this.fire("update");
	},

	_updateStage: function () {
		let b: L.Bounds = this._bounds;
		let stage: PIXI.Container = this._ctx;

		stage.cullArea = new PIXI.Rectangle(b.min?.x, b.getSize().x, b.getSize().y);

		stage.transform.position.set(-(b.min?.x || 0), -(b.min?.y || 0));
	},

	// Draw the canvas
	_draw: function () {
		var layer;
		let bounds = this._redrawBounds;

		this._drawing = true;

		for (var order = this._drawFirst; order; order = order.next) {
			layer = order.layer;
			if (!bounds || (layer._pxBounds && layer._pxBounds.intersects(bounds))) {
				layer._updatePath();
			}
		}

		this._drawing = false;
	},

	// Clear the canvas
	_clear: function () {
		// alert("called");
	},

	_onMouseMove: function (e: any) {},

	_handleMouseOut: function () {},

	_initPath: function (layer: any) {
		// @ts-ignore
		L.Canvas.prototype._initPath.call(this, layer);

		const { iconUrl, size } = layer.options;
		if (!iconUrl) return;

		let texture = this._textures[iconUrl];

		if (!texture) {
			texture = PIXI.Texture.from(iconUrl);
			this._textures[iconUrl] = texture;
		}

		let sprite = new PIXI.Sprite(texture);
		sprite.interactive = true;
		sprite.anchor.set(0.5, 0.5);
		sprite.width = size[0];
		sprite.height = size[1];
		sprite.roundPixels = true;
		sprite.cullable = false;
		sprite.on("click", () => layer.handleClick(layer));
		layer.sprite = sprite;
		this._ctx.addChild(sprite);
	},

	_removePath: function (layer: any) {
		var order = layer._order;
		var next = order.next;
		var prev = order.prev;

		if (next) {
			next.prev = prev;
		} else {
			this._drawLast = prev;
		}
		if (prev) {
			prev.next = next;
		} else {
			this._drawFirst = next;
		}

		delete layer._order;

		delete this._layers[Util.stamp(layer)];

		layer.sprite.visible = false;

		this._requestRedraw(layer);
	},

	_onClick: function (e: any) {
		var point = this._map.mouseEventToLayerPoint(e),
			layer,
			clickedLayer;

		for (var order = this._drawFirst; order; order = order.next) {
			layer = order.layer;
			if (layer.options.interactive && layer._containsPoint(point)) {
				if (!(e.type === "click" || e.type === "preclick") || !this._map._draggableMoved(layer)) {
					clickedLayer = layer;
				}
			}
		}
		this._fireEvent(
			clickedLayer ? [clickedLayer] : false,
			e,
			e.type === "pointerup" ? "pointerUp" : e.type
		);
	},
	_preUpdate: function () {
		// Update pixel bounds of renderer container (for positioning/sizing/clipping later)
		// Subclasses are responsible of firing the 'update' event.
		var p = this.options.padding,
			size = this._map.getSize(),
			min = this._map.containerPointToLayerPoint(size.multiplyBy(-p)).round();

		this._bounds = new L.Bounds(min, min.add(size.multiplyBy(1 + p * 2)).round());

		this._center = this._map.getCenter();
		this._zoom = this._map.getZoom();
	},
});

import React from "react";
import { twMerge } from "tailwind-merge";
type LogoProps = {
	imageUrl?: string;
	className?: string;
};
const Logo: React.FC<LogoProps> = ({ imageUrl, className }) => {
	return <img src={imageUrl} className={twMerge("w-full", className)} alt='LOGO' />;
};

export default Logo;

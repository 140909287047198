import axios, { AxiosRequestConfig } from "axios";
import { BookmarksAddResponse, BookmarksListResponse } from "../types";
import { computed_url } from "./api";

export const getBookmarks = (token: string) => {
	return new Promise<BookmarksListResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "post",
			url: `${computed_url}/bookmarksApi/getAll`,
			headers: { Authorization: `Bearer ${token}` },
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const addBookmarks = (token: string, markerId: string) => {
	const formData = new FormData();
	formData.append("marker_id", markerId);

	return new Promise<BookmarksAddResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "post",
			url: `${computed_url}/bookmarksApi/add`,
			headers: { Authorization: `Bearer ${token}` },
			data: formData,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const deleteBookmark = (token: string, bookmarkId: string) => {
	const formData = new FormData();
	formData.append("bookmark_id", bookmarkId);

	return new Promise<BookmarksAddResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "post",
			url: `${computed_url}/bookmarksApi/delete`,
			headers: { Authorization: `Bearer ${token}` },
			data: formData,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

import React from "react";
import { MapDataType } from "../../types/MapTypes";
import Category from "./Category";
type GroupsProps = {
	mapData: MapDataType;
};

const Groups: React.FC<GroupsProps> = ({ mapData }) => {
	return (
		<>
			{mapData.groups?.map((group) =>
				group.categories?.map(
					(cat) =>
						cat.data && (
							<Category key={`cat-${cat.id}`} item={cat} tileMode={mapData.tiles_mode === "1"} />
						)
				)
			)}
		</>
	);
};

export default Groups;

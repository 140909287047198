import { Portal, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import useScreen from "use-screen";
import cs from "classnames";
import "./MediaModal.scss";

type MediaModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

const MediaModal: React.FC<MediaModalProps> = ({ isOpen, onClose, children }) => {
	const { isMobile } = useScreen();

	return (
		<Portal>
			<Transition as={Fragment} show={isOpen}>
				<div className={cs("media-modal", { isMobile: isMobile })}>
					<Transition.Child
						as={Fragment}
						enter='transition duration-[500ms]'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='transition duration-[500ms]'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='media-modal-overlay' onClick={onClose}></div>
					</Transition.Child>
					<div className='media-modal-content-wrapper'>
						<Transition.Child
							as={Fragment}
							enter='transform transition-all duration-[500ms]'
							enterFrom='opacity-0 scale-75 translate-y-20'
							enterTo='opacity-100 scale-100 translate-y-0'
							leave='transition-all duration-[200ms]'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-75'
						>
							<div className='modal-content'>{children}</div>
						</Transition.Child>
					</div>
				</div>
			</Transition>
		</Portal>
	);
};

export default MediaModal;

import React from "react";
import Control from "react-leaflet-custom-control";
import { ControlButton } from "../../components";
import { BiNote } from "react-icons/bi";
import { useTranslation } from "react-i18next";

type NotesControlProps = {
	onClick: () => void;
};

const NotesControl: React.FC<NotesControlProps> = (props) => {
	const { onClick } = props;

	const { t } = useTranslation();

	return (
		<Control position='topleft'>
			<ControlButton onClick={onClick} tooltip={t("userFeatures.markerTooltip")}>
				<BiNote />
			</ControlButton>
		</Control>
	);
};

export default NotesControl;

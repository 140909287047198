import React from "react";
import { Button } from "../../components";
import cs from "classnames";
import { CustomButtonType } from "../../types/SiteOptionsType";
import { v4 as uuid } from "uuid";
import { useSiteOptions } from "../../hooks";

const CustomButtons: React.FC = () => {
	const { siteOptions } = useSiteOptions();

	const onButtonClick = (btn: CustomButtonType) => {
		window && window.open(btn.link, "_blank");
	};

	const getSkeleton = () => {
		return (
			<>
				<div className={"flex-1 h-9 bg-black/20"} />
				<div className={"flex-1 h-9 bg-black/20"} />
				<div className={"flex-1 h-9 bg-black/20"} />
			</>
		);
	};

	return (
		<div
			className={cs("flex mt-0 space-x-2.5", { "animate-pulse": siteOptions.state === "loading" })}
		>
			{siteOptions.state === "hasError" && (
				<div className='flex-1 flex items-center justify-center text-white h-9 rounded-sm bg-red-900/90'>
					Error....
				</div>
			)}
			{siteOptions.state === "loading" && getSkeleton()}
			{siteOptions.state === "hasValue" &&
				siteOptions.contents?.sidebarButtons?.map((btn) =>
					btn.text !== "" ? (
						<Button key={uuid()} className='flex-1' onClick={() => onButtonClick(btn)}>
							{btn.text}
						</Button>
					) : null
				)}
		</div>
	);
};

export default CustomButtons;

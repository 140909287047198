import cx from "classnames";
import { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Loader } from "../../components";
import { useAppDispatch, useAppSelector, useMaps } from "../../hooks";
import { MapsActions } from "../../store-redux/slices/map/mapsSlice";
import { STATUS } from "../../types";
import MapsDropdownGroup from "./MapDropdownGroup";
import MapsDropdownItem from "./MapsDropdownItem";

interface SearchBoxProps extends React.HTMLAttributes<HTMLDivElement> {
	onSelectMap: () => void;
}

const MapsDropdown: React.FC<SearchBoxProps> = ({ className, onSelectMap, ...restProps }) => {
	const dispatch = useAppDispatch();
	const mapsState = useAppSelector((state) => state.maps);
	const [isOpen, setOpen] = useState(false);
	const { currentMapData, currentMap, setCurrentMap } = useMaps();

	useEffect(() => {
		dispatch(MapsActions.fetchMaps());
	}, []);

	const handleOpen = () => {
		if (mapsState.status === STATUS.SUCCESS) {
			setOpen(!isOpen);
		}
	};

	const selectMap = (mapId: string) => {
		setCurrentMap(mapId, true, true);
		setOpen(false);
		onSelectMap();
	};

	return (
		<div
			className={cx(
				"bg-theme-800 text-base rounded-sm relative select-none cursor-pointer",
				className
			)}
			{...restProps}
		>
			<div
				className="px-2.5 py-2 text-white flex justify-between items-center"
				onClick={handleOpen}
			>
				{mapsState.status === STATUS.PENDING ? (
					<Loader className="!h-5 !w-5" />
				) : mapsState.status === STATUS.ERROR ? (
					<div className="text-red-500">Error</div>
				) : (
					<>
						<span>{currentMapData.getValue()?.name}</span>
						<span className="text-lg">
							<FiChevronDown />
						</span>
					</>
				)}
			</div>
			{isOpen ? (
				<div className="absolute bg-theme-900 mt-1 rounded-sm w-full z-50 text-white space-y-1 shadow-lg">
					{mapsState.list.map((item) => {
						if (item.group) {
							return (
								<MapsDropdownGroup
									group={item as any}
									activeMapId={currentMap}
									selectMap={selectMap}
								/>
							);
						}

						return item.maps.map((map) => (
							<MapsDropdownItem
								map={map}
								active={currentMapData.getValue()?.id === map.id}
								selectMap={selectMap}
							/>
						));
					})}
				</div>
			) : null}
		</div>
	);
};

export default MapsDropdown;

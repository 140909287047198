import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useMaps } from "../../hooks";
import { MapCategoryType } from "../../types/MapTypes";

import "./CategoryItem.scss";

interface CategoryItemProps extends React.HTMLAttributes<HTMLDivElement> {
	item: MapCategoryType;
	onToggle?: (item: MapCategoryType) => void;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ item, onToggle, className, ...restProps }) => {
	const { t } = useTranslation();
	const { toggleCategory, isCategoryHidden } = useMaps();

	return (
		<div
			className={cx("category-item", { hidden: isCategoryHidden(item.id) })}
			onClick={() => toggleCategory(item.id)}
		>
			<img src={item.icon_image} className="h-5" />
			<span>{t(`category.${item.id}`, { defaultValue: item.name })}</span>
		</div>
	);
};

export default CategoryItem;

import { atom } from "recoil";
import { getInfoModalOptions, getUpdates } from "../services/api";
import { UpdatesModalType } from "../types/ModalTypes";
import { InfoModalOptionsType } from "../types/SiteOptionsType";

export const UpdatesModalState = atom<UpdatesModalType>({
	key: "UpdatesModalState",
	default: getUpdates(),
});

export const UpdatesModalOpenState = atom<boolean>({
	key: "UpdatesModalOpenState",
	default: false,
});

export const MediaModalState = atom<string | null>({
	key: "MediaModalState",
	default: "",
});

export const MediaModalOpenState = atom<boolean>({
	key: "MediaModalOpenState",
	default: false,
});

export const MediaModalTypeState = atom<"image" | "iframe" | "imgur">({
	key: "MediaModalTypeState",
	default: "image",
});

export const ShareModalOpenState = atom<boolean>({
	key: "ShareModalOpenState",
	default: false,
});

import {
	CircleMarkerProps,
	createPathComponent,
	updateCircle,
	LeafletContextInterface,
	LeafletElement,
} from "@react-leaflet/core";
import { CircleMarker } from "leaflet";
import { PixiMarker } from "../../libs/PixiMarker";

export interface SVGMarkerProps {
	iconUrl: string;
	size: [number, number];
}

export function extendContext(
	source: LeafletContextInterface,
	extra: Partial<LeafletContextInterface>
): LeafletContextInterface {
	return { ...source, ...extra };
}

export function createElementObject<T, C = any>(
	instance: T,
	context: LeafletContextInterface,
	container?: C | null
): LeafletElement<T, C> {
	return { instance, context, container };
}

function createCircleMarker(props: SVGMarkerProps & CircleMarkerProps, ctx: LeafletContextInterface) {
	const { center, ...options } = props;
	const instance: CircleMarker<any> = new PixiMarker(center, options);
	let ret: LeafletElement<CircleMarker<any>, any> = {
		instance,
		context: { ...ctx, overlayContainer: instance },
	};
	return ret;
}

const CanvasMarker = createPathComponent<CircleMarker, SVGMarkerProps & CircleMarkerProps>(
	createCircleMarker,
	updateCircle
);

export default CanvasMarker;

import React from "react";
import Tooltip from "react-tooltip";
import { InnerTooltipType } from "../../types";
import "./PopupTooltip.scss";

type PopupTooltipProps = {
	tip: InnerTooltipType;
	imageUrl?: string;
};

const PopupTooltip: React.FC<PopupTooltipProps> = ({ tip, imageUrl }) => {
	let tipId = `popup-tooltip-${tip.id}.${Math.random() * 100}`;
	return (
		<>
			{imageUrl ? (
				<img src={imageUrl} alt={`top-${tip.id}`} data-tip data-for={tipId} />
			) : (
				<span data-tip data-for={tipId}>
					{tip.name}
				</span>
			)}

			<Tooltip id={tipId} place='bottom' className='w-52 popup-tooltip' effect='solid'>
				<div className='no-styles' dangerouslySetInnerHTML={{ __html: tip.content }}></div>
			</Tooltip>
		</>
	);
};

export default PopupTooltip;

import React, { useEffect, useRef } from "react";
import { FiMessageSquare } from "react-icons/fi";
import { ControlButton, PopupTooltip, SidePopup, YoutubeIframe } from "../../components";
import Control from "react-leaflet-custom-control";
import { SidePopupContentState, SidePopupModeState } from "../../store";
import { useRecoilState } from "recoil";
import { useMap, useMapEvent } from "react-leaflet";
import { useBookmarks, useMaps, useMediaModal, useSiteOptions, useTooltips } from "../../hooks";
import L from "leaflet";
import { Slide, toast } from "react-toastify";
import parse, { Element } from "html-react-parser";
import { useTranslation } from "react-i18next";
import useMarkerToggle from "../../hooks/useMarkerToggle";

const SidePopupControl: React.FC = () => {
	const [popup, setPopup] = useRecoilState(SidePopupContentState);
	const [isSidePopupMode, setSidePopupMode] = useRecoilState(SidePopupModeState);
	const { currentMap } = useMaps();
	const { siteOptions } = useSiteOptions();
	const { openModal } = useMediaModal();
	const { error, loading, getTooltip } = useTooltips();
	const { addBookmark } = useBookmarks();
	const { t } = useTranslation();

	useEffect(() => {
		var div = L.DomUtil.get("sidepopup");
		if (div) {
			L.DomEvent.on(div, "mousewheel", L.DomEvent.stopPropagation);
		}
	}, [popup, isSidePopupMode]);

	useEffect(() => {
		setPopup(null);
	}, [currentMap]);

	useEffect(() => {
		if (siteOptions.state === "hasValue" && siteOptions.contents?.popup_mode === "on_side") {
			setSidePopupMode(true);
		}
	}, [siteOptions]);

	const toggleSidePopupMode = () => {
		if (siteOptions.getValue()?.popup_mode === "on_side") return;
		if (isSidePopupMode) {
			setSidePopupMode(false);
		} else {
			setSidePopupMode(true);
		}
	};

	const handleCopyClick = () => {
		// make url
		if (!popup) return;
		let url = new URL(window.location.toString());
		url.searchParams.set("marker_id", popup.id);

		navigator.clipboard.writeText(url.toString());

		toast.success("Link copied", {
			position: "bottom-center",
			autoClose: 1000,
			hideProgressBar: true,
			transition: Slide,
		});
	};

	const handleVideoClick = (videoUrl: string) => {
		openModal(videoUrl, "iframe");
	};

	const getRenderedContent = (content: string) => {
		return parse(content, {
			replace: (domNode) => {
				if (domNode instanceof Element) {
					const { tagName, attribs } = domNode;
					if (tagName === "iframe" && attribs.src.startsWith("//www.youtube.com")) {
						return <YoutubeIframe embedUrl={attribs.src} onClick={() => handleVideoClick(attribs.src)} />;
					}
					if ((tagName === "a" && attribs.href?.startsWith("tip")) || attribs.href?.startsWith("#tip")) {
						if (error || loading) {
							return null;
						}
						let tooltipId = attribs.href?.split("-").at(-1);
						let tip = getTooltip(tooltipId as string);
						if (!tip) return null;
						return <PopupTooltip tip={tip} />;
					}
					if ((tagName === "img" && attribs.alt?.startsWith("tip")) || attribs.alt?.startsWith("#tip")) {
						if (error || loading) {
							return null;
						}
						let tooltipId = attribs.alt?.split("-").at(-1);
						let tip = getTooltip(tooltipId as string);
						if (!tip) return null;
						return <PopupTooltip tip={tip} imageUrl={attribs.src} />;
					}
				}
			},
		});
	};

	if (siteOptions.state === "hasValue") {
		let data = siteOptions.contents;

		if (data?.popup_mode === "on_map") {
			return null;
		}

		return (
			<Control position='topright' style={{ flexGrow: 1, flexShrink: 1, overflow: "hidden" }}>
				<ControlButton
					onClick={toggleSidePopupMode}
					active={isSidePopupMode}
					tooltip={t("controls.tips.popupMode")}
					tooltipPosition='left'
				>
					<FiMessageSquare />
				</ControlButton>
				{popup && isSidePopupMode && (
					<SidePopup
						title={t(`category.${popup.category_id}`, { defaultValue: popup.name })}
						imageUrl={popup.image}
						content={getRenderedContent(popup.content)}
						onClose={() => setPopup(null)}
						onBookmark={() => addBookmark(popup.id)}
						onCopyLink={handleCopyClick}
						onToggleMarker={() => popup.toggleMarker()}
						copyText={t("map.copyLink")}
						toggleMarkerText={t("map.toggleMarker")}
					/>
				)}
			</Control>
		);
	}
	return null;
};

export default SidePopupControl;

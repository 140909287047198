import React from "react";
import { ControlButton } from "../../components";
import Control from "react-leaflet-custom-control";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useMap } from "react-leaflet";

type ZoomControlProps = {
	position: "topleft" | "topright" | "bottomright" | "bottomleft";
};

const ZoomControl: React.FC<ZoomControlProps> = ({ position }) => {
	const map = useMap();

	return (
		<Control position={position}>
			<ControlButton className='!rounded-b-none' onClick={() => map.zoomIn()}>
				<FaPlus />
			</ControlButton>
			<ControlButton className='!rounded-t-none' onClick={() => map.zoomOut()}>
				<FaMinus />
			</ControlButton>
		</Control>
	);
};

export default ZoomControl;

import React from "react";
import { Slide, toast } from "react-toastify";
import "./CopyBox.scss";
const CopyBox: React.FC<{ value: string; copyMessage: string }> = ({
	children,
	value,
	copyMessage,
}) => {
	const copyText = () => {
		navigator.clipboard.writeText(value);
		toast.success(copyMessage, {
			position: "bottom-center",
			autoClose: 1000,
			hideProgressBar: true,
			transition: Slide,
		});
	};

	return (
		<div className='copy-box' onClick={copyText}>
			{children}
		</div>
	);
};

export default CopyBox;

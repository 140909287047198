export type InnerTooltipType = {
	id: string;
	name: string;
	content: string;
};

export type TimezoneItem = {
	id: string;
	name: string;
	timezone: string;
};

export enum STATUS {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
	[Property in Key]-?: Type[Property];
  };

export type zeroOrOne = "0" | "1";
export * from "./commentTypes";
export * from "./bookmarksType";

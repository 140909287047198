import { Transition } from "@headlessui/react";
import React from "react";
import { FiX } from "react-icons/fi";

type ProgressPopupProps = {
	isOpen: boolean;
	title: string;
	onClose: () => void;
};

const ProgressPopup: React.FC<ProgressPopupProps> = (props) => {
	const { isOpen, title, onClose, children } = props;

	return (
		<Transition
			as={React.Fragment}
			show={isOpen}
			enter='transition ease-out duration-[200ms] origin-top-right'
			enterFrom='transform opacity-0 scale-90'
			enterTo='transform opacity-100 scale-100'
			leave='transition ease-in duration-75 origin-top-right'
			leaveFrom='transform opacity-100 scale-100'
			leaveTo='transform opacity-0 scale-95'
		>
			<div className='absolute min-w-[350px] right-full top-0 mx-2'>
				<div className='rounded bg-theme-800 px-4 py-1 text-white shadow-lg border border-theme-900'>
					<h3 className='py-2 text-base font-semibold flex items-center justify-between'>
						<span>{title}</span>
						<button
							className='rounded-full p-0.5 bg-white/20 transition-colors hover:bg-red-500'
							onClick={onClose}
						>
							<FiX />
						</button>
					</h3>
					<div>{children}</div>
				</div>
			</div>
		</Transition>
	);
};

export default ProgressPopup;

import React from "react";
import { FiPlay } from "react-icons/fi";

import "./YoutubeIframe.scss";

type YoutubeIframeProps = {
	embedUrl: string;
	onClick: () => void;
};

const YoutubeIframe: React.FC<YoutubeIframeProps> = ({ embedUrl, onClick }) => {
	const getVideoId = () => {
		return embedUrl.split("/").filter((i) => i !== "")[2];
	};

	const getThumbnailUrl = () => {
		return `https://img.youtube.com/vi/${getVideoId()}/maxresdefault.jpg`;
	};

	return (
		<div className='yt-container' onClick={onClick}>
			<img src={getThumbnailUrl()} alt='Youtube Video' />
			<div className='yt-play'>
				<FiPlay size={20} className='ml-1' />
			</div>
		</div>
	);
};

export default YoutubeIframe;

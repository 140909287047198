import L from "leaflet";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAlignJustify } from "react-icons/fa";
import Control from "react-leaflet-custom-control";
import { useRecoilValue } from "recoil";
import useScreen from "use-screen";
import { ControlButton, ScreenModal as ScreenModalComponent } from "../../components";
import { useSiteOptions } from "../../hooks";
import { computed_url } from "../../services/api";
import { RightSidebarOpenState, SidebarOpenState, TopNavHeightState } from "../../store";

const ScreenModalControl: React.FC = () => {
	const isSidebarOpen = useRecoilValue(SidebarOpenState);
	const isRightSidebarOpen = useRecoilValue(RightSidebarOpenState);
	const [isOpen, setOpen] = useState(false);
	const { siteOptions } = useSiteOptions();
	const topNavHeight = useRecoilValue(TopNavHeightState);
	const { isMobile } = useScreen();

	const { t } = useTranslation();

	useEffect(() => {
		if (isOpen) {
			var div = L.DomUtil.get("screen_modal");
			if (div) {
				L.DomEvent.on(div, "mousewheel", L.DomEvent.stopPropagation);
			}
		}
	}, [isOpen]);

	const getTopOffset = (): number => {
		// Check for mobile
		if (isMobile) {
			return 56;
		}
		// Check if the Maps are on the top
		if (siteOptions.state === "hasValue" && siteOptions.contents?.sidebar_position === "top") {
			return topNavHeight;
		}
		return 0;
	};

	return (
		<>
			<Control position='topleft'>
				<ControlButton onClick={() => setOpen(true)} tooltip={t("controls.tips.screenModal")}>
					<FaAlignJustify />
				</ControlButton>
			</Control>

			<ScreenModalComponent
				id={"screen_modal"}
				isOpen={isOpen}
				onClose={() => setOpen(false)}
				left={isSidebarOpen && !isMobile ? 400 : 0}
				top={getTopOffset()}
				right={isRightSidebarOpen && !isMobile ? 300 : 0}
			>
				<iframe
					className='content-iframe'
					title='screenPopup'
					src={`${computed_url}/api/screen_modal_view`}
					frameBorder='0'
				/>
			</ScreenModalComponent>
		</>
	);
};

export default ScreenModalControl;

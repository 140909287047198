import { atom } from "recoil";
import { UserFeature, UserType } from "../types/userTypes";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const UserLoadingState = atom<boolean>({
	key: "UserLoadingState",
	default: false,
});

export const UserLoggedInState = atom<boolean>({
	key: "UserLoggedInState",
	default: false,
});

export const UserTokenIdState = atom<string | null>({
	key: "UserTokenIdState",
	default: null,
	effects: [persistAtom],
});

export const UserRefreshTokenState = atom<string | null>({
	key: "UserRefreshTokenState",
	default: null,
	effects: [persistAtom],
});

export const UserState = atom<UserType | null>({
	key: "UserState",
	default: null,
});

export const UserFeaturesState = atom<UserFeature[]>({
	key: "UserFeaturesState",
	default: [],
});

import _ from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { useAppDispatch, useAppSelector } from ".";
import { toastOptions } from "../config";
import { addUserVisited, deleteUserVisited } from "../services/userApi";
import { CurrentMapDataState, HiddenCategoriesState, UserTokenIdState } from "../store";
import { VisitedMarkersActions } from "../store-redux/slices/map/visitedMarkersSlice";
import { STATUS } from "../types";

const useMarkerToggle = () => {
	// const hiddenMarkers = useRecoilValueLoadable(HiddenMarkersState);
	const { list: hiddenMarkers, status } = useAppSelector((state) => state.visited);
	const token = useRecoilValue(UserTokenIdState);
	const currentMapData = useRecoilValueLoadable(CurrentMapDataState);
	const [hiddenCategories, setHiddenCategories] = useRecoilState(HiddenCategoriesState);
	const { t } = useTranslation();

	const d = useAppDispatch();

	useEffect(() => {
		if (status === STATUS.IDLE && token) {
			d(VisitedMarkersActions.fetchVisitedMarkers(token));
		}
	}, []);


	const toggleMarker = (markerId: string, sibling_identifier: string) => {
		let mapData = currentMapData.getValue();
		let siblings: string[] = [];
		if (mapData && sibling_identifier !== "") {
			mapData.groups.forEach((group) => {
				group.categories?.forEach((cat) => {
					cat.data?.forEach((marker) => {
						if (marker.properties.sibling_identifier === sibling_identifier) {
							siblings.push(marker.properties.id);
						}
					});
				});
			});
		}

		if (siblings.length === 0) {
			siblings.push(markerId);
		}

		let includesAny = _.intersection(hiddenMarkers, siblings).length !== 0;

		if (!token) return;

		if (includesAny) {
			// removing
			// syncVisitedMakers(siblings, "delete");
			d(
				VisitedMarkersActions.syncVisitedMarkers({
					token,
					markers: siblings,
					action: "remove",
				})
			);
		} else {
			// Adding
			// syncVisitedMakers(siblings, "add");
			d(
				VisitedMarkersActions.syncVisitedMarkers({
					token,
					markers: siblings,
					action: "add",
				})
			);
		}
		if (!token) return;
	};

	const syncVisitedMakers = async (markerIds: string[], action: "add" | "delete") => {
		if (!token) {
			return;
		}

		if (action === "add") {
			let added = await addUserVisited(token, markerIds);
			if (added.status === "error") {
				toast.error(t("userProgress.syncError"), toastOptions);
			}
		}

		if (action === "delete") {
			let deleted = await deleteUserVisited(token, markerIds);
			if (deleted.status === "error") {
				toast.error(t("userProgress.syncError"), toastOptions);
			}
		}
	};

	const toggleAll = () => {
		if (currentMapData.state === "hasValue" && currentMapData.contents) {
			let groups = currentMapData.contents.groups;
			let catIds: any = groups.map((g) => g.categories);
			catIds = _.flatten(catIds).map((cat: any) => cat.id);

			if (hiddenCategories.length === catIds.length) {
				setHiddenCategories([]);
			} else {
				setHiddenCategories(catIds);
			}
		}
	};

	return { hiddenMarkers: hiddenMarkers, toggleMarker, toggleAll };
};

export default useMarkerToggle;

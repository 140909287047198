import { atom } from "recoil";
import { getRightSidebarOptions } from "../services/api";
import { RightSidebarOptionsType } from "../types/SiteOptionsType";

export const SidebarOpenState = atom<boolean>({
	key: "SidebarOpenState",
	default: true,
});

export const RightSidebarOpenState = atom<boolean>({
	key: "RightSidebarOpenState",
	default: false,
});

export const RightSidebarContentState = atom<RightSidebarOptionsType>({
	key: "RightSidebarContentState",
	default: getRightSidebarOptions(),
});

export const MainSidebarTabIndex = atom<number>({
	key: "MainSidebarTabIndex",
	default: 0,
});

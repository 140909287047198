import L from "leaflet";
import React, { useEffect, useState } from "react";
import { Marker, useMap, useMapEvent } from "react-leaflet";
import { useMaps } from "../../hooks";

const FocusManager: React.FC = () => {
	const map = useMap();
	const { goBack } = useMaps();
	const [marker, setMarker] = useState<any | null>(null);

	useMapEvent("contextmenu", () => {
		goBack();
	});

	const Icon = L.icon({
		iconUrl: "images/link_marker.png",
		iconAnchor: [15, 15],
		iconSize: [30, 30],
	});

	useEffect(() => {
		let flyTo = getCoordinatesFromURL();
		if (flyTo) {
			map.flyTo([flyTo.lat, flyTo.lng], flyTo.zoom);
			setMarker(flyTo);
		}
	}, []);

	const getCoordinatesFromURL = (): null | { lat: number; lng: number; zoom: number } => {
		let url = new URL(window.location.toString());
		let lat = url.searchParams.get("lat");
		let lng = url.searchParams.get("lng");
		let zoom = url.searchParams.get("zoom");
		if (lat && lng) {
			return { lat: parseFloat(lat), lng: parseFloat(lng), zoom: zoom ? parseFloat(zoom) : 10 };
		}
		return null;
	};

	if (marker) {
		return <Marker position={[marker.lat, marker.lng]} icon={Icon} />;
	}

	return null;
};

export default FocusManager;

import React from "react";
import { useSiteOptions } from "../../hooks";

const SiteTitle: React.FC = () => {
	const { siteOptions } = useSiteOptions();

	if (siteOptions.state === "loading" || siteOptions.state === "hasError") {
		return null;
	}

	if (siteOptions.contents?.siteTitle === "") {
		return null;
	}
	return (
		<h1 className='text-center text-white text-xl font-bold my-3'>
			{siteOptions.contents?.siteTitle}
		</h1>
	);
};

export default SiteTitle;

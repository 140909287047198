import React, { useMemo } from "react";
import { Button, Loader } from "../../components";
import { FaDiscord, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { useSiteOptions, useUpdates } from "../../hooks";
import { useTranslation } from "react-i18next";
const HeaderButtons: React.FC = () => {
	const { setOpen } = useUpdates();
	const { siteOptions } = useSiteOptions();
	const {
		t,
		i18n: { language },
	} = useTranslation();

	const { state, contents: siteOptionsData } = siteOptions;
	const { facebook_link, twitter_link, instagram_link, discord_link } = siteOptionsData;

	const getButtons = useMemo(() => {
		if (state === "loading") {
			return <Loader />;
		}

		if (state === "hasError" || !siteOptionsData) {
			return null;
		}

		return (
			<>
				{discord_link && (
					<Button link={t("links.discord", { defaultValue: discord_link })} newWindow>
						<FaDiscord />
					</Button>
				)}
				{facebook_link && (
					<Button link={t("links.facebook", { defaultValue: facebook_link })} newWindow>
						<FaFacebook />
					</Button>
				)}
				{instagram_link && (
					<Button link={t("links.instagram", { defaultValue: instagram_link })} newWindow>
						<FaInstagram />
					</Button>
				)}
				{twitter_link && (
					<Button link={t("links.twitter", { defaultValue: twitter_link })} newWindow>
						<FaTwitter />
					</Button>
				)}
			</>
		);
	}, [siteOptions, language]);

	return (
		<div className="flex space-x-2.5 justify-center mt-3 mb-3">
			<Button onClick={() => setOpen(true)}>{t("sidebar.updates")}</Button>
			{state === "loading" && <Loader />}
			{siteOptionsData ? (
				<>
					{discord_link && (
						<Button link={t("links.discord", { defaultValue: discord_link })} newWindow>
							<FaDiscord />
						</Button>
					)}
					{facebook_link && (
						<Button
							link={t("links.facebook", { defaultValue: facebook_link })}
							newWindow
						>
							<FaFacebook />
						</Button>
					)}
					{instagram_link && (
						<Button
							link={t("links.instagram", { defaultValue: instagram_link })}
							newWindow
						>
							<FaInstagram />
						</Button>
					)}
					{twitter_link && (
						<Button link={t("links.twitter", { defaultValue: twitter_link })} newWindow>
							<FaTwitter />
						</Button>
					)}
				</>
			) : null}
		</div>
	);
};

export default HeaderButtons;

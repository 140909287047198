import React, { useState } from "react";
import { FiMapPin, FiMenu, FiMessageCircle, FiUser, FiX } from "react-icons/fi";
import { useRecoilState } from "recoil";
import { TopNav, TopNavTabs } from "../../components";
import { TopNavLogo } from "../../components/TopNav/TopNav";
import { useAppSelector } from "../../hooks";
import { MainSidebarTabIndex } from "../../store";
import CommentsList from "../Comments/CommentsList";
import MapsDropdown from "../MapsDropdown/MapsDropdown";
import MobileSearchBox from "../MobileSearchBox/MobileSearchBox";
import MobileSearchResults from "../MobileSearchBox/MobileSearchResults";
import Profile from "../Profile/Profile";
import Clock from "./Clock";
import CustomButtons from "./CustomButtons";
import LanguageDropdown from "./LanguageDropdown";
import MarkerGroups from "./MarkerGroups";
import ShowHideButton from "./ShowHideButton";

const MobileNav: React.FC = () => {
	const [activeTab, setActiveTab] = useRecoilState(MainSidebarTabIndex);
	const [navOpen, setNavOpen] = useState(true);
	return (
		<>
			<TopNav>
				<div className="upper">
					<TopNavLogo />
					<div className="flex-1" />
					<div className="flex items-center space-x-2">
						<LanguageDropdown />
					</div>
				</div>

				<div className="lower">
					<TopNavTabs
						items={[
							{
								label: <FiMapPin />,
								content: (
									<>
										<CustomButtons />
										<div className="grid grid-cols-2 gap-x-2 my-2">

											<MapsDropdown
												className="flex-1"
												onSelectMap={() => setNavOpen(false)}
											/>
											<MobileSearchBox className="flex-1" />
										</div>
										<MobileSearchResults />
										{/* <Maps className="mt-2 border-y border-theme-600" /> */}
										{/* <ShowHideButton /> */}
										<Clock isMini />
										<MarkerGroups />
									</>
								),
							},
							{
								label: <FiMessageCircle />,
								content: <CommentsList />,
							},
							{ label: <FiUser />, content: <Profile /> },
						]}
						active={activeTab}
						onChange={(newTab: number) => setActiveTab(newTab)}
						open={navOpen}
						setOpen={setNavOpen}
					/>
				</div>
			</TopNav>
		</>
	);
};

export default MobileNav;

import { useRecoilState } from "recoil";
import { MediaModalOpenState, MediaModalState, MediaModalTypeState } from "../store/modalsState";

const useMediaModal = () => {
	const [media, setMedia] = useRecoilState(MediaModalState);
	const [isOpen, setOpen] = useRecoilState(MediaModalOpenState);
	const [type, setType] = useRecoilState(MediaModalTypeState);
	const openModal = (url: string, mediaType: "image" | "iframe" = "image") => {
		setType(mediaType);
		setMedia(url);
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
		setMedia(null);
	};

	return { isOpen, closeModal, openModal, media, type };
};

export default useMediaModal;

import _, { isArray, map } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { FiCopy, FiLoader, FiPlus, FiTrash } from "react-icons/fi";
import { useRecoilState } from "recoil";
import { Modal } from "../components";
import { useLogin, useMaps } from "../hooks";
import { createUserShareLink, deleteUserShareLink, getUserShareLinks } from "../services/userApi";
import { ShareModalOpenState } from "../store/modalsState";
import { ShareLinkType } from "../types/userTypes";
import { toast } from "react-toastify";
import { toastOptions } from "../config";
import { StringOptions } from "sass";
import { useTranslation } from "react-i18next";

type LinkItemProps = {
	item: ShareLinkType;
	onUpdate: () => void;
};
const LinkItem: React.FC<LinkItemProps> = ({ item, onUpdate }) => {
	const siteURL = new URL(window.location.toString());
	const { token } = useLogin();

	const copyLink = () => {
		let link = `${window.location.href.split("?")[0]}?l=${item.link}`;
		navigator.clipboard.writeText(link.toString());

		toast.success("Link copied..", toastOptions);
	};

	const deleteLink = async () => {
		if (!token) return;
		const res = await deleteUserShareLink(token, item.id);
		if (res.status === "success") {
			onUpdate();
		} else {
			toast.error("Unable to delete link", toastOptions);
		}
	};

	return (
		<div className="flex space-x-2">
			<div className="px-2 py-1.5 flex-1 rounded bg-black/20">{`${
				window.location.href.split("?")[0]
			}?l=${item.link}`}</div>
			<button
				onClick={copyLink}
				className="w-9 h-9 flex items-center justify-center bg-black/30 transition-colors hover:bg-lime-500 rounded"
			>
				<FiCopy />
			</button>
			<button
				onClick={deleteLink}
				className="w-9 h-9 flex items-center justify-center bg-black/30 transition-colors text-red-700 hover:bg-red-500 hover:text-white rounded"
			>
				<FiTrash />
			</button>
		</div>
	);
};

const CreateShareLink: React.FC<{ onAdd: any }> = ({ onAdd }) => {
	const [isLoading, setLoading] = useState(false);
	const { token } = useLogin();
	const { currentMap } = useMaps();
	const handleAdd = async () => {
		if (!token) return;
		setLoading(true);
		const res = await createUserShareLink(token, currentMap);
		if (res.status === "success") {
			onAdd();
		}
		setLoading(false);
	};

	return (
		<button
			onClick={handleAdd}
			className="w-full bg-black/20 rounded transition-colors hover:bg-black/30 py-2 px-3 flex justify-center items-center"
		>
			{isLoading ? (
				<FiLoader className="animate-spin h-6" />
			) : (
				<>
					<FiPlus className="mr-2" /> Create New for current map
				</>
			)}
		</button>
	);
};

const ShareModal: React.FC = () => {
	const [isOpen, setOpen] = useRecoilState(ShareModalOpenState);
	const [userLinks, setUserLinks] = useState<any[]>([]);
	const [error, setError] = useState<null | string>(null);
	const { token } = useLogin();
	const { maps } = useMaps();
	const { t } = useTranslation();

	useEffect(() => {
		fetchLinks();
	}, [isOpen, token]);

	const fetchLinks = async () => {
		setError(null);
		if (!token || !isOpen) return;
		const res = await getUserShareLinks(token);
		if (!isArray(res)) {
			setError("Unable to fetch share links");
		} else {
			setUserLinks(res);
		}
	};

	type LinkGroup = {
		mapId: string;
		mapName: string;
		links: ShareLinkType[];
	};

	const groupedLinks: LinkGroup[] = useMemo(() => {
		if (userLinks.length <= 0) return [];
		if (maps.state !== "hasValue") return [];

		let result: LinkGroup[] = [];
		_.mapKeys(_.groupBy(userLinks, "map_id"), (group, x) => {
			result.push({
				mapId: x,
				mapName: group[0].map_name,
				links: group,
			});
		});
		return result;
	}, [userLinks]);

	return (
		<Modal isOpen={isOpen} title="Share Modal" onClose={() => setOpen(false)}>
			{userLinks && (
				<div className="space-y-2 my-3">
					<CreateShareLink onAdd={() => fetchLinks()} />
					{error && <div>{error}</div>}
					{!error && userLinks.length === 0 && (
						<div>No links found . Create a new link with button above.</div>
					)}
					{groupedLinks.map((group) => {
						return (
							<>
								<h1 className="font-bold">
									{t(`maps.${group.mapId}`, { defaultValue: group.mapName })}
								</h1>
								{group.links.map((link: ShareLinkType) => (
									<LinkItem item={link} onUpdate={() => fetchLinks()} />
								))}
							</>
						);
					})}
				</div>
			)}
		</Modal>
	);
};

export default ShareModal;

import classNames from "classnames";
import { Tab } from "@headlessui/react";
import "./TopNavTabs.scss";
import React, { useEffect, useRef } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

type TopNavTabsProps = {
	items: TabItemType[];
	active: number;
	onChange: (e: number) => void;
	open: boolean;
	setOpen: (e: boolean) => void;
};

type TabItemType = {
	label: string | React.FC | JSX.Element;
	content: string | React.FC | JSX.Element;
};

const TopNavTabs: React.FC<TopNavTabsProps> = (props) => {
	const { items, active, onChange, open, setOpen } = props;
	const tabContentRef = useRef<HTMLDivElement>(null);
	const tabsRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		console.log(tabContentRef);
		// Add Event Listener to tabContentRef
		if (tabContentRef.current) {
			tabContentRef.current.addEventListener("scroll", handleScroll);
		}
		return () => {
			// Remove Event Listener from tabContentRef
			if (tabContentRef.current) {
				tabContentRef.current.removeEventListener("scroll", handleScroll);
			}
		};
	}, []);

	const handleScroll = () => {
		console.log("called");
		if (tabContentRef.current) {
			// if scroll is greather than 0 then make tabs sticky
			if (tabContentRef.current.scrollTop > 0) {
				if (tabsRef.current) {
					tabsRef.current.classList.add("sticky");
				}
			}
			// if scroll is 0 then remove sticky class
			else {
				if (tabsRef.current) {
					tabsRef.current.classList.remove("sticky");
				}
			}
		}
	};

	return (
		<div className="topnav-container">
			{open ? (
				<Tab.Group selectedIndex={active} onChange={onChange}>
					<Tab.List className="topnav-tabs" ref={tabsRef}>
						{items.map((item) => {
							return (
								<Tab as={React.Fragment}>
									{({ selected }) => {
										let btnClass = classNames("topnav-tab", {
											active: selected,
										});
										return <div className={btnClass}>{item.label}</div>;
									}}
								</Tab>
							);
						})}
					</Tab.List>

					<Tab.Panels
						className="px-2 py-3 overflow-y-scroll max-h-[45vh]"
						ref={tabContentRef}
					>
						{items.map((item) => {
							return <Tab.Panel>{item.content}</Tab.Panel>;
						})}
					</Tab.Panels>
				</Tab.Group>
			) : null}
			<div className="handle" onClick={() => setOpen(!open)}>
				{open ? <FiChevronUp /> : <FiChevronDown />}
			</div>
		</div>
	);
};

export default TopNavTabs;

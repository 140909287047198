import { LatLngExpression } from "leaflet";
import React, { useMemo } from "react";
import { Polygon } from "react-leaflet";
import { flipLatLng, flipPoly } from "../../helpers";
import { MapCategoryType, MapFeatureType } from "../../types/MapTypes";
import Marker from "../Marker/Marker";
import PolylineDecorator from "../Polyline/PolylineDecorator";

type FeatureProps = {
	feature: MapFeatureType;
	category: MapCategoryType;
	tileMode: boolean;
};

const Feature: React.FC<FeatureProps> = ({ feature, category, tileMode }) => {
	const positions = useMemo(() => {
		if (feature.type === "Point") {
			return flipLatLng(feature.coordinates, tileMode);
		}
		if (feature.type === "Polygon" || feature.type === "LineString") {
			return flipPoly(feature.coordinates, tileMode);
		}
		return feature.coordinates as LatLngExpression;
	}, [feature.type, feature.coordinates, tileMode]);

	return (
		<>
			{feature.type === "Point" && (
				<Marker position={positions} category={category} marker={feature} />
			)}
			{feature.type === "Polygon" && (
				<Polygon
					positions={positions as LatLngExpression[][]}
					color={feature.properties.primary_color}
				/>
			)}
			{feature.type === "LineString" && (
				<PolylineDecorator
					positions={positions as LatLngExpression[]}
					color={feature.properties.primary_color}
					dashArray={feature.properties.path_spacing}
					type={feature.properties.line_head}
				/>
			)}
		</>
	);
};

export default Feature;

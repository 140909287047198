import React, { useCallback, useEffect, useState } from "react";
import { useMaps } from "../../hooks";
import { MapCategoryType } from "../../types/MapTypes";
import useCluster from "use-supercluster";
import { useMap } from "react-leaflet";
import Feature from "./Feature";
import { useRecoilValue } from "recoil";
import { HiddenCategoriesState } from "../../store";
import { isCategoryHidden } from "../../helpers/categoryHelpers";
type CategoryProps = {
	item: MapCategoryType;
	tileMode: boolean;
};

const Category: React.FC<CategoryProps> = ({ item, tileMode }) => {
	const hiddenCategories = useRecoilValue(HiddenCategoriesState);

	const [zoom, setZoom] = useState(0);
	const map = useMap();
	const points = item.data
		.filter((r) => r.type === "Point")
		.map((r) => {
			return { type: "Feature", geometry: { ...r }, properties: r.properties };
		});
	const others = item.data.filter((r) => r.type !== "Point");

	const onZoom = useCallback(() => {
		setZoom(map.getZoom());
	}, [map]);

	useEffect(() => {
		map.on("move", onZoom);
		return () => {
			map.off("move", onZoom);
		};
	}, [map, onZoom]);

	const { clusters } = useCluster({
		points,
		bounds: [-9999, -9999, 9999, 9999],
		zoom: zoom,
		options: { radius: 50, minZoom: -1, maxZoom: parseFloat(item.showFromZoom) - 1 },
	});

	if (isCategoryHidden(hiddenCategories, item.id) || !item.data) return null;

	return (
		<>
			{clusters.map((cluster) => {
				const { cluster: isCluster } = cluster.properties;
				if (isCluster) {
					return null;
				}
				return (
					<Feature
						key={cluster.properties.id}
						feature={cluster.geometry}
						category={item}
						tileMode={tileMode}
					/>
				);
			})}
			{others.map((feature) => {
				return (
					<Feature
						key={feature.properties.id}
						feature={feature}
						category={item}
						tileMode={tileMode}
					/>
				);
			})}
		</>
	);
};

export default Category;

import React from "react";
import { FiShare2 } from "react-icons/fi";
import Control from "react-leaflet-custom-control";
import { useSetRecoilState } from "recoil";
import { ControlButton } from "../../components";
import { ShareModalOpenState } from "../../store/modalsState";
import ShareModal from "../ShareModal";

const ShareControl: React.FC = () => {
	const setOpen = useSetRecoilState(ShareModalOpenState);

	return (
		<Control position='topleft'>
			<ControlButton onClick={() => setOpen(true)}>
				<FiShare2 />
			</ControlButton>
			<ShareModal />
		</Control>
	);
};

export default ShareControl;

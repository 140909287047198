import React from "react";
import { useTranslation } from "react-i18next";
import { CgArrowsExpandUpRight } from "react-icons/cg";
import Control from "react-leaflet-custom-control";
import { ControlButton } from "../../components";

type LineControlProps = {
	onClick: () => void;
};

const LineControl: React.FC<LineControlProps> = (props) => {
	const { onClick } = props;

	const { t } = useTranslation();

	return (
		<Control position='topleft'>
			<ControlButton onClick={onClick} tooltip={t("userFeatures.lineTooltip")}>
				<CgArrowsExpandUpRight />
			</ControlButton>
		</Control>
	);
};

export default LineControl;

import { Transition } from "@headlessui/react";
import React, { ButtonHTMLAttributes } from "react";
import "./TopNav.scss";

export const TopNavButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	...restProps
}) => {
	return (
		<button className="top-nav-btn" {...restProps}>
			{children}
		</button>
	);
};

export const TopNavLogo: React.FC = () => {
	return (
		<a className="logo" href="/">
			&nbsp;
		</a>
	);
};

export const TopNavDropDown: React.FC<{ isOpen: boolean }> = ({ isOpen, children }) => {
	return (
		<Transition
			as={React.Fragment}
			show={isOpen}
			enter="transform transition-all origin-top duration-[500ms]"
			enterFrom="opacity-0 scale-75"
			enterTo="opacity-100 scale-100"
			leave="transform transition-all origin-top duration-[200ms]"
			leaveFrom="opacity-100 scale-100"
			leaveTo="opacity-0 scale-75"
		>
			<div className="absolute top-full left-0 right-0 bg-theme-800 px-4 py-3 flex flex-col">
				{children}
			</div>
		</Transition>
	);
};

const TopNav: React.FC = ({ children }) => {
	return <div className="top-nav">{children}</div>;
};

export default TopNav;

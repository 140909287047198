import classNames from "classnames";
import React from "react";

interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {}

const Loader: React.FC<LoaderProps> = ({ className }) => {
	return (
		<div
			className={classNames(
				"mx-auto rounded-full shrink-0 animate-spin border-2 border-white/20 h-6 w-6 border-r-white/70",
				className
			)}
		></div>
	);
};

export default Loader;

import React from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Control from "react-leaflet-custom-control";
import { useRecoilState } from "recoil";
import useScreen from "use-screen";
import { ControlButton } from "../../components";
import { SidebarOpenState } from "../../store";

const SidebarControl: React.FC = () => {
	const [sidebarOpen, setSidebarOpen] = useRecoilState(SidebarOpenState);
	const { isMobile } = useScreen();
	const { t } = useTranslation();

	if (isMobile) return <></>;

	return (
		<Control position='topleft'>
			<ControlButton
				onClick={() => setSidebarOpen(!sidebarOpen)}
				tooltip={t("controls.tips.toggleSidebar")}
			>
				{sidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
			</ControlButton>
		</Control>
	);
};

export default SidebarControl;

import React, { useEffect } from 'react';
import {
	CustomButtons,
	Header,
	HeaderButtons,
	Maps,
	MarkerGroups,
	MobileNav,
	SearchBox,
	ShowHideButton,
	SiteTitle,
} from './';
import { Sidebar, SidebarTabs } from '../../components';
import useScreen from 'use-screen';
import { useRecoilState } from 'recoil';
import { MainSidebarTabIndex, SidebarOpenState } from '../../store';
import { useMaps } from '../../hooks';
import _ from 'lodash';
import { FiMapPin, FiMessageCircle, FiUser } from 'react-icons/fi';
import UpdatesModal from '../UpdatesModal';
import Profile from '../Profile/Profile';
import { CommentsList } from '..';
import Clock from './Clock';

const MainSidebar: React.FC = () => {
	const { isMobile } = useScreen();
	const [isOpen, setOpen] = useRecoilState(SidebarOpenState);
	const { currentMap, currentMapData, setHiddenCategories } = useMaps();
	const [active, setActive] = useRecoilState(MainSidebarTabIndex);

	useEffect(() => {
		if (currentMapData.state === 'hasValue' && currentMapData.contents) {
			let hidden = currentMapData.contents.groups?.map((group) =>
				group.categories?.filter((cat) => cat.hide_initially === '1').map((cat) => cat.id)
			) as any;
			hidden = _.flatten(hidden);

			setHiddenCategories(hidden);
		}
	}, [currentMap, currentMapData]);

	if (isMobile) {
		return <MobileNav />;
	}

	return (
		<Sidebar isOpen={isOpen} onClose={() => setOpen(false)} alwaysScroll>
			<Header />
			<SiteTitle />
			<Clock />
			<HeaderButtons />
			<UpdatesModal />
			<SidebarTabs
				active={active}
				onChange={(idx) => setActive(idx)}
				items={[
					{
						label: <FiMapPin />,
						content: (
							<>
								<CustomButtons />
								<Maps className='mt-2 border-y border-theme-600' />
								<ShowHideButton />
								<SearchBox />
								<MarkerGroups />
							</>
						),
					},
					{
						label: <FiMessageCircle />,
						content: <CommentsList />,
					},
					{
						label: <FiUser />,
						content: <Profile />,
					},
				]}
			/>
		</Sidebar>
	);
};

export default MainSidebar;

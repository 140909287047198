import L from "leaflet";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { PopupProps, Tooltip } from "react-leaflet";
import useMarkerToggle from "../../hooks/useMarkerToggle";
import { MapFeatureType } from "../../types/MapTypes";
import "./MarkerTooltip.scss";

interface MarkerTooltipProps extends PopupProps {
	marker: MapFeatureType;
}

const MarkerTooltip: React.FC<MarkerTooltipProps> = ({ marker, ...restProps }) => {
	const { properties } = marker;
	const { t } = useTranslation();
	const tooltipRef = useRef<L.Tooltip>(null);
	const { hiddenMarkers } = useMarkerToggle();
	useEffect(() => {
		const { current: tooltipCurrent } = tooltipRef;
		if (hiddenMarkers?.includes(marker.properties.id)) {
			tooltipCurrent && tooltipCurrent.setOpacity(0.5);
		} else {
			tooltipCurrent && tooltipCurrent.options.opacity !== 1 && tooltipCurrent.setOpacity(1);
		}
	}, [hiddenMarkers]);

	const position = () => {
		if (properties.only_text === "1") {
			return "center";
		}
		switch (properties.icon_text_position) {
			case "1":
				return "left";
			case "2":
				return "top";
			case "3":
				return "right";
			case "4":
				return "bottom";
			default:
				return "right";
		}
	};

	return (
		<Tooltip
			ref={tooltipRef}
			className="marker-tooltip"
			direction={position()}
			permanent
			interactive
			{...restProps}
		>
			<span style={{ color: properties.icon_text_color, fontSize: properties.icon_text_size + "px" }}>
				{t(`markerTexts.${properties.id}`, { defaultValue: properties.icon_text })}
			</span>
		</Tooltip>
	);
};

export default MarkerTooltip;

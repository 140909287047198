import L from "leaflet";
import React, { useEffect, useMemo, useRef } from "react";
import { Marker } from "react-leaflet";
import { downScaleLatLng, upScaleLatLng } from "../../helpers";
import { useMaps } from "../../hooks";
import useNotes from "../../hooks/useNotes";
import { UserMarker } from "../../types/userTypes";
import NotePopup from "./NotePopup";
import "./Notes.scss";
const NoteMarkers: React.FC = () => {
	const { userFeatures } = useNotes();
	const { currentMap } = useMaps();

	let noteMarkers: UserMarker[] = userFeatures.filter(
		(row) => row.type === "marker" && row.map_id === currentMap
	) as UserMarker[];

	return (
		<>
			{noteMarkers.map((marker) => {
				return <NoteMarker marker={marker} />;
			})}
		</>
	);
};

type NoteMarkerProps = {
	marker: UserMarker;
};

const NoteMarker: React.FC<NoteMarkerProps> = (props) => {
	const { marker } = props;
	const { activeNote, deleteFeature, updateFeature } = useNotes();
	const { currentMapData } = useMaps();
	const markerRef = useRef<L.Marker>(null);

	const handleChange = (notes: string) => {
		updateFeature(marker.id, notes);
	};

	useEffect(() => {
		if (activeNote === marker.id) {
			markerRef.current?.openPopup();
		}
	}, [activeNote, marker.id]);

	const position = useMemo(() => {
		let geometry = JSON.parse(marker.geometry);
		if (currentMapData.state === "hasValue") {
			if (marker.tiles_coordinates === currentMapData.contents?.tiles_mode) {
				return geometry;
			} else {
				if (marker.tiles_coordinates === "1") {
					// Down scale
					return downScaleLatLng([geometry.lat, geometry.lng]);
				} else {
					// Up scale
					return upScaleLatLng([geometry.lat, geometry.lng]);
				}
			}
		}
	}, [marker.geometry]);

	return (
		<Marker position={position} icon={L.icon({ iconUrl: "images/note.png", iconSize: [36, 36] })} ref={markerRef}>
			<NotePopup feature={marker} onDelete={() => deleteFeature(marker.id)} onChangeNote={handleChange} type='marker' />
		</Marker>
	);
};

export default NoteMarkers;

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { toastOptions } from "../config";
import {
	getBookmarks,
	addBookmarks as addBookmarkMain,
	deleteBookmark as deleteBookmarkMain,
} from "../services/bookmarksApi";
import { UserTokenIdState } from "../store";
import { BookmarksState } from "../store/bookmarksState";

const useBookmarks = () => {
	const token = useRecoilValue(UserTokenIdState);
	const [bookmarks, setBookmarks] = useRecoilState(BookmarksState);
	const { t } = useTranslation("", { keyPrefix: "bookmarks" });

	const { refetch, data, isLoading, error } = useQuery(
		"bookmarksList",
		() => getBookmarks(token || ""),
		{
			enabled: false,
		}
	);

	useEffect(() => {
		if (!isLoading && !error && data) {
			if (data.status === "success") {
				setBookmarks(data.bookmarks);
			} else {
				setBookmarks([]);
			}
		} else {
			setBookmarks([]);
		}
	}, [data, isLoading, error, setBookmarks]);

	const addBookmark = async (markerId: string) => {
		if (token) {
			let inList = bookmarks.find((e) => e.marker_id === markerId);
			if (inList) return;
			toast
				.promise(
					() => addBookmarkMain(token, markerId),
					{
						pending: t("adding"),
						success: t("added"),
						error: t("addingError"),
					},
					toastOptions
				)
				.then(() => refetch());
		} else {
			toast.error(t("loginError"), toastOptions);
		}
	};

	const deleteBookmark = async (bookmarkId: string) => {
		if (token) {
			toast
				.promise(
					() => deleteBookmarkMain(token, bookmarkId),
					{
						pending: t("deleting"),
						success: t("deleted"),
						error: t("deletingError"),
					},
					toastOptions
				)
				.then(() => refetch());
		} else {
			toast.error(t("loginError"), toastOptions);
		}
	};

	return { bookmarks, loading: isLoading, error, refetch, addBookmark, deleteBookmark };
};

export default useBookmarks;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import visitedMarkersSlice from "./slices/map/visitedMarkersSlice";
import userLanguageSlice from "./slices/user/userLanguageSlice";
import mapsSlice from "./slices/map/mapsSlice";
import categorySearchSlice from "./slices/categorySearch/categorySearchSlice";

const rootReducer = combineReducers({
	visited: visitedMarkersSlice,
	userLanguageSlice,
	maps: mapsSlice,
	categorySearch: categorySearchSlice,
});

const store = configureStore({
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

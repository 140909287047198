import { latLng } from "leaflet";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiHome } from "react-icons/fi";
import { useMap } from "react-leaflet";
import Control from "react-leaflet-custom-control";
import { ControlButton } from "../../components";
import { useMaps } from "../../hooks";

const HomeControl: React.FC = () => {
	const map = useMap();
	const { currentMapData } = useMaps();
	const { t } = useTranslation();

	const goToHome = () => {
		if (currentMapData.state === "hasValue" && currentMapData.contents) {
			let currentMap = currentMapData.contents;
			let zoom = currentMap.zoom;
			let center = currentMap?.tiles_mode === "1" ? latLng(2048, 2048) : latLng(6, 6);
			map.setView(center, parseInt(zoom));
		}
	};

	return (
		<Control position='topleft'>
			<ControlButton onClick={goToHome} tooltip={t("controls.tips.home")}>
				<FiHome />
			</ControlButton>
		</Control>
	);
};

export default HomeControl;

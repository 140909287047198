import { t } from "i18next";
import { isArray } from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { Dropdown } from "../../components";
import { getTranslations } from "../../services/api";
import { CommentsLanguageState } from "../../store";

const CommentFilters: React.FC = () => {
	const { data: languages } = useQuery("translations", getTranslations);
	const [, setCommentsLanguage] = useRecoilState(CommentsLanguageState);

	return (
		<div className='mt-4 flex justify-end'>
			<Dropdown
				tooltip={t("comments.languageTooltip")}
				items={
					isArray(languages)
						? languages.map((row) => ({
								name: row.name,
								value: row.handle,
								image: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${row.country_code}.svg`,
						  }))
						: []
				}
				onChange={(item) => {
					setCommentsLanguage(item.value);
				}}
			/>
		</div>
	);
};

export default CommentFilters;

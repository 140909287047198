import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import './SidebarTabs.scss';

type TabItem = {
	label: string | React.FC | JSX.Element;
	content: string | React.FC | JSX.Element;
};

type SidebarTabsProps = {
	items: TabItem[];
	active: number;
	onChange: (index: number) => void;
};

const SidebarTabs: React.FC<SidebarTabsProps> = (props) => {
	const { items, active, onChange } = props;
	const sidebarTabs = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		let leftSidebar = document.getElementById('sidebar-wrapper-left');
		if (leftSidebar) {
			leftSidebar.onscroll = handleScroll;
		}
	}, []);

	const handleScroll = () => {
		console.log('scrolling');
		let leftSidebar = document.getElementById('sidebar-wrapper-left');
		let container = sidebarTabs.current;
		if (container && leftSidebar) {
			// Make sidebar tabs sticky
			let realScroll = -(leftSidebar.scrollTop - container.offsetTop);
			if (realScroll <= 0) {
				container.classList.add('sticky');
			} else {
				container.classList.remove('sticky');
			}
		}
	};

	return (
		<Tab.Group selectedIndex={active} onChange={onChange}>
			<div className='sidebar-tabs' ref={sidebarTabs}>
				<Tab.List className='sidebar-tabs-list'>
					{items.map((item) => {
						return (
							<Tab as={React.Fragment}>
								{({ selected }) => {
									let btnCls = classNames('tab-item', { selected: selected });
									return <button className={btnCls}>{item.label}</button>;
								}}
							</Tab>
						);
					})}
				</Tab.List>
				<div className='sidebar-tabs-list-placeholder'></div>
				<Tab.Panels className='sidebar-tabs-panels'>
					{items.map((item) => {
						return <Tab.Panel className='tab-panel'>{item.content}</Tab.Panel>;
					})}
				</Tab.Panels>
			</div>
		</Tab.Group>
	);
};

export default SidebarTabs;

import React, { useEffect, useState } from "react";
import { FiEdit, FiLogOut, FiSave } from "react-icons/fi";

type UserBlockProps = {
	profileImage: string;
	username: string;
	email: string;
	onClickLogout: () => void;
	onClickSave: (username: string) => void;
};

const UserBlock: React.FC<UserBlockProps> = (props) => {
	const { profileImage, username, email, onClickLogout, onClickSave } = props;

	const [mode, setMode] = useState<"view" | "edit">("view");
	const [user, setUser] = useState(username);

	useEffect(() => {
		setUser(username);
	}, [username]);

	const handleSave = () => {
		if (user === "") return;
		setMode("view");
		onClickSave(user);
	};

	return (
		<div className='flex items-center px-3 p-4 bg-white/10 rounded'>
			<div className='w-12 h-13 mr-2'>
				<img src={profileImage} alt='' className='rounded-full' />
			</div>
			<div className='flex-1'>
				<div className='text-lg font-semibold '>
					{mode === "view" ? (
						user
					) : (
						<input
							className='bg-black/20 outline-none'
							type='text'
							value={user}
							onChange={(e) => setUser(e.target.value)}
						/>
					)}
				</div>
				<div className='text-sm text-white/70'>{email}</div>
			</div>

			<div className='flex space-x-1'>
				{mode === "view" ? (
					<button
						onClick={() => setMode("edit")}
						className='w-8 h-8 flex items-center justify-center bg-white/10 rounded-full hover:bg-blue-500'
					>
						<FiEdit />
					</button>
				) : (
					<button
						onClick={handleSave}
						className='w-8 h-8 flex items-center justify-center bg-white/10 rounded-full hover:bg-lime-500'
					>
						<FiSave />
					</button>
				)}
				<button
					onClick={onClickLogout}
					className='w-8 h-8 flex items-center justify-center bg-white/10 rounded-full hover:bg-black/10'
				>
					<FiLogOut />
				</button>
			</div>
		</div>
	);
};

export default UserBlock;

import { Listbox } from "@headlessui/react";
import cs from "classnames";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { TimezoneItem } from "../../types";

import Dropdown from "../Dropdown/Dropdown";

type ClockProps = {
	timezones: TimezoneItem[];
	localTimeTitle: string;
	serverTimeTitle: string;
	isMini?: boolean;
};

const Clock: React.FC<ClockProps> = ({
	timezones,
	localTimeTitle,
	serverTimeTitle,
	isMini = false,
}) => {
	const [time, setTime] = useState<string | undefined>();
	const [serverTimezone, setServerTimezone] = useState<string | undefined>();

	useEffect(() => {
		let timer = window.setInterval(function () {
			setTime(moment().format("h : mm : ss a").toString());
		}, 1000);

		return () => {
			window.clearInterval(timer);
		};
	}, []);

	const getTimezoneTime = (timezone: string) => {
		return moment().tz(timezone).format("h : mm : ss a");
	};

	return (
		<div className="flex text-white space-x-2 w-full">
			<div
				className={cs(
					"flex flex-col items-center justify-center flex-1 bg-black/10 py-2 rounded",
					{ "px-2 !items-center": isMini }
				)}
			>
				<div
					className={cs("text-center text-base font-bold", {
						"text-xs text-left": isMini,
					})}
				>
					{localTimeTitle}
				</div>
				<div className={cs({ "text-sm": isMini })}>{time}</div>
			</div>
			<div className="flex flex-col items-center justify-center flex-1 bg-black/10 py-1 rounded">
				<Dropdown
					onChange={(item) => setServerTimezone(item.value)}
					items={timezones.map((t) => ({ name: t.name, value: t.timezone }))}
					renderButton={(selected) => (
						<Listbox.Button>
							<div
								className={cs("text-center text-base font-bold", {
									"text-xs": isMini,
								})}
							>
								{serverTimeTitle}
							</div>
							<div className={cs({ "text-sm text-left": isMini })}>
								{getTimezoneTime(selected.value)}
							</div>
							<div className={cs("text-xs", { "text-[10px]": isMini })}>
								{selected.name}
							</div>
						</Listbox.Button>
					)}
				/>
			</div>
		</div>
	);
};

export default Clock;

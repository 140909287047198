import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "../../config";
import { useMaps } from "../../hooks";
import { getShareLinkFeatures } from "../../services/userApi";
import { UserFeature, UserMarker, UserPolygon, UserPolyline } from "../../types/userTypes";
import { Marker as LeafletMarker, Polygon as LeafletPolygon } from "react-leaflet";
import L from "leaflet";
import NotePopup from "./NotePopup";

import LeafletPolyline from "../Polyline/PolylineDecorator";

const ShareLinkFeatures: React.FC = () => {
	const [link, setLink] = useState<string | null>();
	const [linkFeatures, setLinkFeatures] = useState<UserFeature[]>([]);
	const { setCurrentMap } = useMaps();

	useEffect(() => {
		let url = new URL(window.location.toString());
		let l = url.searchParams.get("l");
		setLink(l);
	}, []);

	useEffect(() => {
		if (!link) return;
		fetchLinkFeatures(link);
	}, [link]);

	const fetchLinkFeatures = async (linkString: string) => {
		const res = await getShareLinkFeatures(linkString);
		if (res.status === "error") {
			if (res.error_type === "invalid_share_link") {
				toast.error("Invalid link", toastOptions);
			}
			if (res.error_type === "no_features") {
				toast.error("Link is empty", toastOptions);
			}
		} else {
			setCurrentMap(res.mapId, false);
			setLinkFeatures(res.features);
		}
	};

	const markers: UserMarker[] = useMemo(
		() => linkFeatures.filter((i) => i.type === "marker") as UserMarker[],
		[linkFeatures]
	);

	const polyline: UserPolyline[] = useMemo(
		() => linkFeatures.filter((i) => i.type === "polyline") as UserPolyline[],
		[linkFeatures]
	);

	const polygon: UserPolygon[] = useMemo(
		() => linkFeatures.filter((i) => i.type === "polygon") as UserPolygon[],
		[linkFeatures]
	);

	if (!link || !linkFeatures) {
		return null;
	}

	return (
		<>
			{markers.map((m) => (
				<Marker item={m} />
			))}
			{polygon.map((p) => (
				<Polygon item={p} />
			))}
			{polyline.map((p) => (
				<Polyline item={p} />
			))}
		</>
	);
};

type MarkerType = {
	item: UserMarker | UserPolyline | UserPolygon;
};

const Marker: React.FC<MarkerType> = (props) => {
	const { item } = props;
	const markerRef = useRef<L.Marker>(null);

	const position = useMemo(() => {
		return JSON.parse(item.geometry);
	}, [item.geometry]);

	return (
		<LeafletMarker
			position={position}
			icon={L.icon({ iconUrl: "images/noteShared.png", iconSize: [36, 36] })}
			ref={markerRef}
		>
			<NotePopup feature={item} type={item.type} readonly={true} />
		</LeafletMarker>
	);
};

type PolygonType = {
	item: UserPolygon;
};

const Polygon: React.FC<PolygonType> = (props) => {
	const { item } = props;

	const position = useMemo(() => {
		return JSON.parse(item.geometry);
	}, [item.geometry]);

	return (
		<LeafletPolygon positions={position} color='#EBC634'>
			<NotePopup feature={item} type={item.type} readonly />
		</LeafletPolygon>
	);
};

type PolylineType = {
	item: UserPolyline;
};

const Polyline: React.FC<PolylineType> = (props) => {
	const { item } = props;

	const geometry = useMemo(() => {
		return JSON.parse(item.geometry);
	}, [item.geometry]);

	return (
		<LeafletPolyline type={geometry.head} dashArray={geometry.lineGap} positions={geometry.latlngs} color='#EBC634'>
			<NotePopup feature={item} type={item.type} readonly />
		</LeafletPolyline>
	);
};

export default ShareLinkFeatures;

import React, { useRef, useState } from "react";
import { ImageOverlay, MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useLogin, useMaps } from "../../hooks";
import { computed_url } from "../../services/api";
import MediaModalView from "./MediaModalView";
import "../../libs/Leaflet.editable";
// @ts-expect-error
import useKeypress from "react-use-keypress";
// @ts-expect-error
import ReactLeafletEditable from "react-leaflet-editable";
import {
	ZoomControl,
	SidebarControl,
	PinLinkControl,
	ScreenModalControl,
	SidePopupControl,
	HomeControl,
	BookmarksControl,
} from "../Controls";
import FocusManager from "./FocusManager";
import ZoomManager from "./ZoomManager";
import RightSidebar from "../Controls/RightSidebarControl";
import L from "leaflet";
import { tilesCrs } from "../../helpers";
import InfoModalControl from "../Controls/InfoModalControl";
import Groups from "./Groups";
import ProgressControl from "../Controls/ProgressControl";
import NotesControl from "../Controls/NotesControl";
import NoteMarkers from "../Notes/NoteMarkers";
import NotePolylines from "../Notes/NotePolylines";
import LineControl from "../Controls/LineControl";
import useNotes from "../../hooks/useNotes";
import PolygonControl from "../Controls/PolygonControl";
import NotePolygones from "../Notes/NotePolygons";
import ShareControl from "../Controls/ShareControl";
import ShareLinkFeatures from "../Notes/ShareLinkFeatures";

const MapView: React.FC = () => {
	const { currentMapData } = useMaps();
	const editRef = useRef<any>();
	const [map, setMap] = useState<L.Map>();
	const { addNotePolyline, addNoteMarker, addNotePolygon } = useNotes();
	const { isLoggedIn } = useLogin();

	const handleDrawingCommit = async (e: any) => {
		let { layer } = e;

		if (layer instanceof L.Polygon) {
			let latlng = layer.getLatLngs();
			if ((latlng[0] as L.LatLng[]).length === 0) return;
			let formatedLatlng = (latlng[0] as L.LatLng[]).map((l: any) => [l.lat, l.lng]);
			await addNotePolygon(formatedLatlng);
			layer.remove();
			return;
		}

		if (layer instanceof L.Polyline && layer.getLatLngs().length !== 0) {
			if ((layer.getLatLngs()[0] as L.LatLng[]).length === 0) return;
			let latlngs = {
				head: "simple",
				lineGap: 0,
				latlngs: layer.getLatLngs().map((l: any) => [l.lat, l.lng]),
			};
			await addNotePolyline(latlngs);
			layer.remove();
			return;
		}

		if (layer instanceof L.Marker) {
			if (!layer.getLatLng().lat) return;
			await addNoteMarker(layer.getLatLng());
			layer.remove();
			return;
		}
	};

	useKeypress("Escape", () => {
		let editTools = (map as any).editTools;
		editTools.drawing() && editTools.stopDrawing();
	});

	const editPoint = () => {
		editRef.current.startMarker(false, {
			icon: L.icon({
				iconUrl: "images/note.png",
				iconRetinaUrl: "images/note.png",
			}),
		});
	};

	const editPolyline = () => {
		editRef.current.startPolyline();
	};

	const editPolygon = () => {
		editRef.current.startPolygon();
	};

	if (currentMapData.state === "hasValue" && currentMapData.contents) {
		let data = currentMapData.contents;
		let center = currentMapData.contents.tiles_mode === "1" ? L.latLng(2048, 2048) : L.latLng(6, 6);
		return (
			<ReactLeafletEditable
				ref={editRef}
				map={map}
				onDrawingCommit={handleDrawingCommit}
				onCancelDrawing={(e: any) => e.layer.remove()}
			>
				<MapContainer
					editable={true}
					key={data.id}
					minZoom={parseInt(data.min_zoom)}
					maxZoom={parseInt(data.max_zoom)}
					zoom={parseInt(data.zoom)}
					center={center}
					zoomSnap={0.001}
					zoomDelta={0.5}
					wheelPxPerZoomLevel={800}
					zoomControl={false}
					inertia
					attributionControl={false}
					crs={data.tiles_mode === "1" ? tilesCrs : L.CRS.EPSG3857}
					whenCreated={setMap}
					scrollWheelZoom={false}
					smoothWheelZoom={true}
					smoothSensitivity={data.zoomSensitivity}
				>
					{/* Left */}
					<ZoomControl position="bottomleft" />
					<SidebarControl />
					<HomeControl />
					<PinLinkControl />
					<ScreenModalControl />
					<InfoModalControl />
					{isLoggedIn && (
						<>
							<NotesControl onClick={editPoint} />
							<LineControl onClick={editPolyline} />
							<PolygonControl onClick={editPolygon} />
							<ShareControl />
						</>
					)}

					{/* Right */}
					<RightSidebar />
					<BookmarksControl />
					<ProgressControl />
					<SidePopupControl />

					<ZoomManager />
					<FocusManager />

					{currentMapData.contents.tiles_mode === "1" ? (
						<TileLayer
							url={`${computed_url}/${currentMapData.contents.tiles_url}`}
							tms={false}
							noWrap={true}
							maxNativeZoom={4}
							minNativeZoom={0}
						/>
					) : (
						<ImageOverlay
							url={`${computed_url}/${currentMapData.contents.image}`}
							bounds={[
								[0, 0],
								[12, 12],
							]}
						/>
					)}
					<Groups mapData={currentMapData.contents} />
					{isLoggedIn && (
						<>
							<NoteMarkers />
							<NotePolylines />
							<NotePolygones />
						</>
					)}
					<ShareLinkFeatures />
					<MediaModalView />
				</MapContainer>
			</ReactLeafletEditable>
		);
	}
	return <></>;
};

export default MapView;

import React from "react";
import { FiStar, FiX } from "react-icons/fi";
import { useMediaModal } from "../../hooks";
import "./SidePopup.scss";
type SidePopupProps = {
	title?: string;
	imageUrl?: string;
	content?: string | JSX.Element | JSX.Element[] | undefined;
	onClose?: () => void;
	onBookmark: () => void;
	onCopyLink: () => void;
	onToggleMarker: () => void;
	copyText: string;
	toggleMarkerText: string;
};

const SidePopup: React.FC<SidePopupProps> = ({
	title,
	imageUrl,
	content,
	onClose,
	onBookmark,
	onCopyLink,
	onToggleMarker,
	copyText,
	toggleMarkerText,
}) => {
	const { openModal } = useMediaModal();

	const handleImageClick = (image: string) => {
		openModal(image);
	};

	return (
		<div className='sidepopup scroll-bar' id='sidepopup'>
			<div className='sidepopup-header'>
				{title && <div className='sidepopup-title'>{title}</div>}
				<div className='flex space-x-2'>
					<div className='sidepopup-bookmark' onClick={() => onBookmark()}>
						<FiStar size={15} />
					</div>
					<div className='sidepopup-close' onClick={() => onClose && onClose()}>
						<FiX size={15} />
					</div>
				</div>
			</div>
			{imageUrl && (
				<img
					className='sidepopup-image'
					onClick={() => handleImageClick(imageUrl)}
					src={imageUrl}
					alt='PopupImage'
				/>
			)}
			{content && <div className='sidepopup-content scroll-bar no-styles'>{content}</div>}
			<div className='sidepopup-buttons'>
				<button className='sidepopup-btn' onClick={onCopyLink}>
					{copyText}
				</button>
				<button className='sidepopup-btn btn-red' onClick={onToggleMarker}>
					{toggleMarkerText}
				</button>
			</div>
		</div>
	);
};

export default SidePopup;

import React, { HTMLAttributes, useEffect, useState } from "react";
import "./TopMaps.scss";
import { css, cx } from "@emotion/css";
import { GroupedMap, MapDataType } from "../../types/MapTypes";
import ReactTooltip from "react-tooltip";
import { getTooltipStyles } from "../../helpers";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const TopMapsItem: React.FC<HTMLAttributes<HTMLDivElement>> = ({
	children,
	className,
	...restProps
}) => {
	return (
		<div className={cx("top-maps-item", className)} {...restProps}>
			{children}
		</div>
	);
};

type TopMapsProps = {
	maps: GroupedMap[];
	onChangeMap: (map: MapDataType) => void;
	activeMapId: string;
	isSidebarOpen: boolean;
	onChangeHeight: (height: number) => void;
};

const TopMaps: React.FC<TopMapsProps> = ({
	maps,
	onChangeMap,
	activeMapId,
	isSidebarOpen,
	onChangeHeight,
}) => {
	const [activeGroup, setActiveGroup] = useState<string | null>(null);
	const { t } = useTranslation();

	useEffect(() => {
		if (activeGroup) {
			let group = maps.find((r) => r.group && r.group.id === activeGroup);
			let activeMapInGroup = group?.maps.find((m) => m.id === activeMapId);
			let firstMap = group?.maps[0];
			if (firstMap && activeMapId !== firstMap.id && !activeMapInGroup) onChangeMap(firstMap);
		}
	}, [activeGroup]);

	const handleMapClick = (map: MapDataType, resetGroup = true) => {
		if (resetGroup) {
			changeHeight();
			setActiveGroup(null);
		} else {
			changeHeight(true);
		}
		onChangeMap(map);
	};

	const handleGroupClick = (groupId: string) => {
		if (activeGroup === groupId) {
			setActiveGroup(null);
			changeHeight();
			return;
		}
		changeHeight(true);
		setActiveGroup(groupId);
	};

	const getActiveGroupMaps = () => {
		let group = maps.find((r) => r.group && r.group.id === activeGroup);

		return group?.maps.map((map) => {
			if (map.hide_on_sidebar === "1") return null;
			return (
				<div
					key={`map-${map.id}`}
					className={cx(
						"sub-item",
						css`
							color: ${map.font_color} !important;
							background-color: ${map.background_color} !important;
							&:hover,
							&.active {
								color: ${map.hover_text_color} !important;
								background-color: ${map.hover_color} !important;
							}
						`,
						{ active: activeMapId === map.id }
					)}
					onClick={() => handleMapClick(map, false)}
					data-tip={map.tooltip}
					data-for={"topmap-" + map.id}
				>
					{t(`maps.${map.id}`, { defaultValue: map.name })}
					<ReactTooltip
						id={"topmap-" + map.id}
						className={cx("w-64", getTooltipStyles(map.tooltip_color, map.tooltip_background))}
						delayShow={1000}
					/>
				</div>
			);
		});
	};

	const changeHeight = (full: boolean = false) => {
		if (onChangeHeight) {
			full ? onChangeHeight(93) : onChangeHeight(53);
		}
	};

	useEffect(() => {
		changeHeight();
		let mapsOnly = maps.map((mapGroup) => mapGroup.maps);
		let flatMaps = _.flatten(mapsOnly);
		let activeMapRow = flatMaps.find((map) => map.id === activeMapId);
		if (activeMapRow) {
			let activeMapGroupId = activeMapRow.map_group_id;
			if (activeMapGroupId !== "0") {
				setActiveGroup(activeMapGroupId);
				changeHeight(true);
			} else {
				setActiveGroup(null);
				changeHeight(false);
			}
		}
	}, [activeMapId]);

	return (
		<div className={"top-maps"}>
			<div
				className={cx(
					css`
						margin-left: ${isSidebarOpen ? 400 : 0}px;
					`,
					"transition-all"
				)}
			>
				<div className={"top-maps-items"}>
					{maps.map((mapsObject) => {
						if (mapsObject.group) {
							const { group } = mapsObject;
							if (group.hide_on_sidebar === "1") return null;
							return (
								<TopMapsItem
									key={`group-${group.id}`}
									className={cx({ active: !!mapsObject.maps.find((i) => i.id === activeMapId) })}
									onClick={() => handleGroupClick(group.id)}
								>
									{t(`mapGroups.${group.id}`, { defaultValue: group.name })}
								</TopMapsItem>
							);
						}
						return mapsObject.maps.map((map) => {
							if (map.hide_on_sidebar === "1") return null;
							return (
								<TopMapsItem
									key={`map-${map.id}`}
									className={cx(
										css`
											color: ${map.font_color} !important;
											background-color: ${map.background_color} !important;
											&:hover,
											&.active {
												color: ${map.hover_text_color} !important;
												background-color: ${map.hover_color} !important;
											}
										`,
										{ active: activeMapId === map.id }
									)}
									onClick={() => handleMapClick(map)}
									data-tip={map.tooltip}
									data-for={"topmap-" + map.id}
								>
									{t(`maps.${map.id}`, { defaultValue: map.name })}
									<ReactTooltip
										id={"topmap-" + map.id}
										className={cx(
											"w-64",
											getTooltipStyles(map.tooltip_color, map.tooltip_background)
										)}
										delayShow={1000}
									/>
								</TopMapsItem>
							);
						});
					})}
				</div>
				{activeGroup && <div className='sub-items'>{getActiveGroupMaps()}</div>}
			</div>
		</div>
	);
};

export default TopMaps;

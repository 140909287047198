import { cx } from "@emotion/css";
import React, { ButtonHTMLAttributes } from "react";
import ReactTooltip from "react-tooltip";
import "./ControlButton.scss";
import { v4 as uuid } from "uuid";
interface ControlButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	tooltip?: string;
	tooltipPosition?: "left" | "right";
	active?: boolean;
}

const ControlButton: React.FC<ControlButtonProps> = ({
	children,
	className,
	tooltip = "",
	tooltipPosition = "right",
	active,
	...restProps
}) => {
	const id = uuid();
	return (
		<button
			className={cx("control-btn", className, { active: active })}
			data-tip={tooltip}
			data-for={id}
			{...restProps}
		>
			{children}
			{tooltip && <ReactTooltip id={id} place={tooltipPosition} effect='solid' delayShow={800} />}
		</button>
	);
};

export default ControlButton;

import Control from "react-leaflet-custom-control";
import React, { useState } from "react";
import { FiCrosshair } from "react-icons/fi";
import { useMapEvent } from "react-leaflet";
import { Portal } from "@headlessui/react";
import { Modal, ControlButton, CopyBox } from "../../components";
import { useMaps } from "../../hooks";
import { useRecoilState } from "recoil";
import { PinLinkModeState } from "../../store";
import { useTranslation } from "react-i18next";

const PinLinkControl: React.FC = () => {
	const [isPinMode, setPinMode] = useRecoilState(PinLinkModeState);
	const [open, setOpen] = useState(false);
	const [pinLocation, setPinLocation] = useState<any>();
	const { currentMap } = useMaps();

	const { t } = useTranslation();

	useMapEvent("click", (e) => {
		if (isPinMode) {
			const clickCoordinates = e.latlng;
			const zoom = e.target._zoom;
			const { lat, lng } = clickCoordinates;
			setOpen(true);
			setPinMode(false);
			setPinLocation({ lat, lng, zoom });
		}
	});

	const getUrl = () => {
		const siteURL = new URL(window.location.toString());
		siteURL.search = "";
		siteURL.searchParams.set("map", currentMap || "");
		siteURL.searchParams.set("lat", pinLocation.lat);
		siteURL.searchParams.set("lng", pinLocation.lng);
		siteURL.searchParams.set("zoom", pinLocation.zoom);
		return siteURL.toString();
	};

	const closeModal = () => {
		setOpen(false);
		setPinLocation(null);
	};

	return (
		<>
			<Portal>
				<Modal isOpen={open} onClose={closeModal}>
					<h2 className='mb-3 text-lg'>{t("modals.pinLink.location_link")}</h2>
					<div className='mb-2'>{t("modals.pinLink.click_to_copy")}</div>
					{pinLocation && (
						<CopyBox value={getUrl()} copyMessage={t("modals.pinLink.copied")}>
							{getUrl()}
						</CopyBox>
					)}
				</Modal>
			</Portal>
			<Control position='topleft'>
				<ControlButton
					active={isPinMode}
					onClick={() => setPinMode(!isPinMode)}
					tooltip={t("controls.tips.pinLink")}
				>
					<FiCrosshair />
				</ControlButton>
			</Control>
		</>
	);
};

export default PinLinkControl;

import React, { HTMLAttributes, MouseEventHandler, useState } from "react";
import AnimateHeight from "react-animate-height";
import { FaChevronDown, FaRegEye } from "react-icons/fa";
import cs from "classnames";
import "./Collapsible.scss";
import { css, cx } from "@emotion/css";
import { zeroOrOne } from "../../types";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { v4 as uuid } from "uuid";

interface CollapsibleItemProps extends HTMLAttributes<HTMLDivElement> {
	imageUrl?: string;
	name: string;
	count?: string;
	disabled?: boolean;
	hideIcon?: boolean;
	hideName?: boolean;
	hideCount?: boolean;
}

export const CollapsibleItem: React.FC<CollapsibleItemProps> = (props) => {
	const {
		imageUrl,
		name,
		count,
		className,
		disabled,
		hideIcon = false,
		hideName = false,
		hideCount = false,
		...restProps
	} = props;

	let cls = classNames("collapsible-item", {
		disabled: disabled,
		"icon-mode": !hideIcon && hideName && hideCount,
		"icon-count-mode": !hideIcon && hideName && !hideCount,
		"name-mode": hideIcon && !hideName && hideCount,
		"count-mode": hideIcon && hideName && !hideCount,
	});
	let uid = uuid();

	let nameCls = classNames("name");
	let iconCls = classNames("icon");
	let countCls = classNames("count");
	let tipCls = classNames(
		css`
			&.show {
				opacity: 1 !important;
			}
		`
	);

	if (hideIcon && hideName && hideCount) return null;

	return (
		<div
			id={uid}
			className={cls}
			data-tip={hideName && name}
			data-for={`collapsible-${uid}`}
			data-iscapture="true"
			data-scroll-hide="true"
			{...restProps}
		>
			{!hideIcon && imageUrl && <img src={imageUrl} alt="" className={iconCls} />}
			{!hideName && <span className={nameCls}>{name}</span>}
			{!hideCount && count && <span className={countCls}>{count}</span>}

			{hideName && (
				<ReactTooltip
					id={`collapsible-${uid}`}
					backgroundColor="#000000"
					className={tipCls}
					getContent={(n) => (hideCount ? `${n} (${count})` : n)}
				/>
			)}
		</div>
	);
};

type CollapsibleProps = {
	title: string;
	items: any;
	buttons: ButtonType[];
	onClickHide: () => void;
};

type ButtonType = {
	id: string;
	name: string;
	text: string;
	link: string;
	color: string;
	background: string;
	hover_color: string;
	hover_background: string;
	sameWindow: zeroOrOne;
};

const Collapsible: React.FC<CollapsibleProps> = ({ title, items, onClickHide, buttons }) => {
	const [isOpen, setOpen] = useState(true);

	const handleLinkClick = (link: string, sameWindow: zeroOrOne) => {
		if (link !== "" && sameWindow === "0") return window.open(link);
		if (link !== "") window.location.href = link;
	};

	const getButtons = () => {
		return buttons.map((btn) => (
			<button
				key={`btn-${btn.id}`}
				className={cx(
					"collapsible-btn",
					css`
						color: ${btn.color};
						background-color: ${btn.background};
						&:hover {
							color: ${btn.hover_color};
							background-color: ${btn.hover_background};
						}
					`
				)}
				onClick={() => handleLinkClick(btn.link, btn.sameWindow)}
			>
				{btn.text}
			</button>
		));
	};

	return (
		<div className={cs("collapsible", { open: isOpen })}>
			<div className="collapsible-title">
				{title}
				<span className="hide" onClick={onClickHide}>
					<FaRegEye />
				</span>
				<span className="collapsible-toggler" onClick={() => setOpen(!isOpen)}>
					<FaChevronDown />
				</span>
			</div>
			<AnimateHeight height={isOpen ? "auto" : 0}>
				{buttons.length > 0 && <div className="collapsible-buttons">{getButtons()}</div>}
				<div className="collapsible-items">{items}</div>
			</AnimateHeight>
		</div>
	);
};

export default Collapsible;

import axios, { AxiosRequestConfig } from "axios";
import {
	AddCommentResponse,
	DeleteCommentResponse,
	GetCommentsResponse,
	VoteCommentResponse,
} from "../types/commentTypes";
import { computed_url } from "./api";

export const getComments = (markerId: string, language: string) => {
	return new Promise<GetCommentsResponse>((resolve, reject) => {
		let data = new FormData();
		markerId && data.append("marker_id", markerId);
		data.append("language", language);

		let config: AxiosRequestConfig = {
			method: "post",
			url: `${computed_url}/commentsApi/list`,
			data: data,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

type AddCommentData = {
	marker_id: string;
	language: string;
	content: string;
};

export const addComment = (token: string, commentData: AddCommentData) => {
	return new Promise<AddCommentResponse>((resolve, reject) => {
		let data = new FormData();

		Object.keys(commentData).forEach((key: string) => {
			data.append(key, commentData[key as keyof AddCommentData]);
		});

		let config: AxiosRequestConfig = {
			method: "post",
			url: `${computed_url}/commentsApi/add`,
			data: data,
			headers: { Authorization: `Bearer ${token}` },
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const deleteComment = (token: string, commentId: string) => {
	return new Promise<DeleteCommentResponse>((resolve, reject) => {
		let config: AxiosRequestConfig = {
			method: "GET",
			url: `${computed_url}/commentsApi/delete?comment_id=${commentId}`,
			headers: { Authorization: `Bearer ${token}` },
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const voteComment = (token: string, commentId: string, vote: "1" | "-1") => {
	return new Promise<VoteCommentResponse>((resolve, reject) => {
		let data = new FormData();
		data.append("comment_id", commentId);
		data.append("vote", vote);

		let config: AxiosRequestConfig = {
			method: "POST",
			url: `${computed_url}/commentsApi/vote`,
			headers: { Authorization: `Bearer ${token}` },
			data,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

export const reportComment = (token: string, commentId: string, content: string) => {
	return new Promise<VoteCommentResponse>((resolve, reject) => {
		let data = new FormData();
		data.append("comment_id", commentId);
		data.append("content", content);

		let config: AxiosRequestConfig = {
			method: "POST",
			url: `${computed_url}/commentsApi/report`,
			headers: { Authorization: `Bearer ${token}` },
			data,
		};
		axios(config)
			.then((res) => resolve(res.data))
			.catch((e) => reject(e));
	});
};

import React from "react";
import { LanguageDropdown } from ".";
import { Logo } from "../../components";

const Header: React.FC = () => {
	return (
		<div className='flex space-x-2 items-center mb-5'>
			<div className='flex-1'>
				<Logo imageUrl='./images/logo2.png' />
			</div>
			<div className='flex-1 max-w-[150px]'>
				<LanguageDropdown />
			</div>
		</div>
	);
};

export default Header;

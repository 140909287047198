import { css, cx } from "@emotion/css";
import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { FiX } from "react-icons/fi";

import "./ScreenModal.scss";

type ScreenModalProps = {
	id?: string;
	isOpen: boolean;
	onClose: () => void;
	left?: number;
	top?: number;
	right?: number;
	bottom?: number;
};

const ScreenModal: React.FC<ScreenModalProps> = ({
	id,
	isOpen,
	onClose,
	children,
	left = 0,
	top = 0,
	right = 0,
	bottom = 0,
}) => {
	return (
		<Transition
			as={Fragment}
			show={isOpen}
			enter='transition duration-[500ms]'
			enterFrom='opacity-0 scale-75'
			enterTo='opacity-100 scale-100'
			leave='transition duration-[500ms]'
			leaveFrom='opacity-100 scale-100'
			leaveTo='opacity-0 scale-75'
		>
			<div
				id={id}
				className={cx(
					"screen-modal",
					css`
						left: ${left}px !important;
						right: ${right}px !important;
						top: ${top}px !important;
						bottom: ${bottom}px !important;
					`
				)}
			>
				<div className='screen-modal-close-wrapper'>
					<button className='screen-modal-close-btn' onClick={onClose}>
						<FiX size={14} />
					</button>
				</div>
				<div className='screen-modal-content'>{children}</div>
			</div>
		</Transition>
	);
};

export default ScreenModal;

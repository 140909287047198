import React from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components";
import Collapsible, { CollapsibleItem } from "../../components/Collapsible/Collapsible";
import { useMaps } from "../../hooks";

const MarkerGroups: React.FC = () => {
	const { currentMapData, isCategoryHidden, toggleCategory, toggleGroup } = useMaps();
	const { t } = useTranslation();

	if (currentMapData.state === "loading" || typeof currentMapData.contents === "undefined") {
		return (
			<div className='py-5 text-center'>
				<Loader />
			</div>
		);
	}

	if (currentMapData.state === "hasError") {
		return <div className='text-red'>error</div>;
	}

	return (
		<div className='mt-3 space-y-2'>
			{currentMapData.contents.groups &&
				currentMapData.contents.groups.map((group) => {
					return (
						<Collapsible
							key={"marker-group-" + group.id}
							title={t(`categoryGroup.${group.id}`, { defaultValue: group.name })}
							onClickHide={() => toggleGroup(group)}
							items={group.categories?.map((cat) => {
								if (cat.hide_on_sidebar === "1") return null;
								return (
									<CollapsibleItem
										key={cat.id}
										name={t(`category.${cat.id}`, { defaultValue: cat.name })}
										imageUrl={cat.icon_image}
										count={cat.data?.length.toString() || "0"}
										disabled={isCategoryHidden(cat.id)}
										hideIcon={group.hide_icon === "1"}
										hideName={group.hide_name === "1"}
										hideCount={group.hide_count === "1"}
										onClick={() => toggleCategory(cat.id)}
									/>
								);
							})}
							buttons={group.buttons}
						/>
					);
				})}
		</div>
	);
};

export default MarkerGroups;

import { latLng } from "leaflet";
import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import { useMaps } from "../../hooks";

const ZoomManager: React.FC = () => {
	const { currentMap, currentMapData } = useMaps();
	const map = useMap();

	useEffect(() => {
		if (currentMap && currentMapData.state === "hasValue") {
			let mapData = currentMapData.contents;
			if (mapData) {
				let center = mapData.tiles_mode === "1" ? latLng(2048, 2048) : latLng(6, 6);
				map.setView(center, parseInt(mapData.zoom));
				map.setMinZoom(Number(mapData.min_zoom));
				map.setMaxZoom(Number(mapData.max_zoom));
			}
		}
	}, [map, currentMap, currentMapData]);

	return null;
};

export default ZoomManager;

import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Loader } from "../../components";
import { useLogin } from "../../hooks";

const LoginButton: React.FC = () => {
	const { login, loading } = useLogin();
	const { t } = useTranslation("", { keyPrefix: "profile" });

	return (
		<Button
			onClick={!loading ? () => login() : () => false}
			className='flex items-center !px-1.5 !py-1.5 !bg-[#4285F4] hover:!bg-[#3972D1]'
		>
			<span className='w-9 h-9 bg-white p-1.5 flex items-center justify-center rounded-sm mr-3'>
				<img src='images/google-icon.png' alt='Google Icon' />
			</span>
			<div
				className={classNames("font-sans normal-case text-base flex-1", {
					"text-center -ml-9": loading,
				})}
			>
				{!loading ? t("login") : <Loader />}
			</div>
		</Button>
	);
};

export default LoginButton;

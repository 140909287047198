import React, { ButtonHTMLAttributes, JSXElementConstructor } from "react";
import { twMerge } from "tailwind-merge";

import "./Button.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	link?: string;
	newWindow?: boolean;
}

const Button: React.FC<ButtonProps> = ({ children, className, link, newWindow, ...restProps }) => {
	const handleClick = () => {
		if (link) {
			if (newWindow) {
				window.open(link, "blank");
				return;
			}

			window.open(link);
		}
	};

	return (
		<button className={twMerge("btn", className)} onClick={handleClick} {...restProps}>
			{children}
		</button>
	);
};

export default Button;

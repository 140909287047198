import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import React from "react";
import ReactTooltip from "react-tooltip";
import { v4 as uuid } from "uuid";

type DropdownMiniProps = {
	items: DropdownMiniItem[];
	tooltip?: string;
	defaultValue?: DropdownMiniItem;
	onChange: (item: DropdownMiniItem) => void;
	renderButton?: (selected: DropdownMiniItem) => React.ReactNode;
};

export type DropdownMiniItem = {
	name?: string;
	value: any;
	image?: string;
};

const DropdownMini: React.FC<DropdownMiniProps> = (props) => {
	const { items, onChange, renderButton, defaultValue, tooltip } = props;

	const [selected, setSelected] = useState(defaultValue || items[0]);

	const handleChange = (item: DropdownMiniItem) => {
		onChange(item);
		setSelected(item);
	};

	const id = uuid();

	return (
		<Listbox value={selected} onChange={(item) => handleChange(item)}>
			<div className="relative">
				{renderButton ? (
					renderButton(selected)
				) : (
					<>
						<Listbox.Button
							data-tip={tooltip}
							data-for={id}
							className="focus:outline-none flex items-center relative w-full cursor-pointer rounded bg-black/20 h-8 py-2.5 px-2  text-left text-white shadow-md focus-visible:border-lime-500 focus-visible:ring-2 focus-visible:ring-lime-200 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
						>
							{selected.image && (
								<span className="w-4">
									<img src={selected.image} alt={selected.image} />
								</span>
							)}
							{selected.name && (
								<span className="block truncate">{selected.name}</span>
							)}
						</Listbox.Button>
						<ReactTooltip id={id} place="top" effect="solid" />
					</>
				)}
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<Listbox.Options className="focus:outline-none absolute mt-1 max-h-60 w-full overflow-auto rounded-sm bg-theme-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm z-[5000]">
						{items.map((row, rowIdx) => (
							<Listbox.Option
								key={rowIdx}
								className={({ active }) =>
									`relative cursor-pointer select-none py-1 px-2 ${
										active ? "bg-white/10 text-white" : "text-white"
									}`
								}
								value={row}
							>
								{({ selected }) => (
									<div className="flex items-center">
										{row.image && (
											<span className="w-5">
												<img src={row.image} alt={row.image} />
											</span>
										)}
										{row.name && (
											<span
												className={`block truncate ${
													selected ? "font-medium" : "font-normal"
												}`}
											>
												{row.name}
											</span>
										)}
									</div>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Transition>
			</div>
		</Listbox>
	);
};

export default DropdownMini;

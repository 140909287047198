import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import {
	ActiveMarkerState,
	CurrentMapState,
	MapNavigationHistoryState,
	MapsListState,
} from "../store";
import { v4 as uuid } from "uuid";
import { MapHistoryType } from "../types/MapTypes";

const useNavigation = () => {
	const [mapHistory, setMapHistory] = useRecoilState(MapNavigationHistoryState);
	const maps = useRecoilValueLoadable(MapsListState);
	const [currentMap, _setCurrentMap] = useRecoilState(CurrentMapState);
	const setActiveMarker = useSetRecoilState(ActiveMarkerState);

	const getDefaultMap = () => {
		if (maps.contents) {
			let firstMap = maps.contents[0].maps[0].id;
			return firstMap;
		}
	};

	const pushToHistory = (history: MapHistoryType) => {
		let newHistory = [...mapHistory];
		newHistory.push(history);
		setMapHistory(newHistory);
	};

	const setCurrentMap = (mapId: string, reset = true, history = false) => {
		const url = new URL(window.location.toString());
		if (reset) {
			url.search = "";
			setActiveMarker(null);
		}

		if (history) {
			pushToHistory({ id: uuid(), mapId: currentMap });
		}

		url.searchParams.set("map", mapId);
		window.history.pushState({}, "", url.toString());
		_setCurrentMap(mapId);
	};

	return {
		setCurrentMap,
		getDefaultMap,
		pushToHistory,

		currentMap,
	};
};

export default useNavigation;

import React, { useState, HTMLAttributes, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { GroupedMap, MapDataType } from "../../types/MapTypes";
import Animate from "react-animate-height";
import "./MapList.scss";
import ReactTooltip from "react-tooltip";
import { css, cx } from "@emotion/css";
import { getTooltipStyles } from "../../helpers";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface MapItemProps extends HTMLAttributes<HTMLDivElement> {
	mapItem: MapDataType;
	active?: boolean;
}

const MapItem: React.FC<MapItemProps> = ({ mapItem, active, ...restProps }) => {
	const { t } = useTranslation();
	if (mapItem.hide_on_sidebar === "1") {
		return null;
	}

	const getClassName = () => {
		return css`
			color: ${mapItem.font_color} !important;
			background-color: ${mapItem.background_color} !important;
			&:hover,
			&.active {
				color: ${mapItem.hover_text_color} !important;
				background-color: ${mapItem.hover_color} !important;
			}
		`;
	};

	return (
		<div
			className={cx("map-list-item", { active: active }, getClassName())}
			{...restProps}
			data-tip={mapItem.tooltip}
			data-for={mapItem.id}
		>
			{t(`maps.${mapItem.id}`, { defaultValue: mapItem.name })}
			<ReactTooltip
				id={mapItem.id}
				effect='solid'
				className={cx("w-64", getTooltipStyles(mapItem.tooltip_color, mapItem.tooltip_background))}
				place='top'
				delayShow={1000}
			/>
		</div>
	);
};

type GroupedMapListProps = {
	group: GroupedMap;
	onChangeMap: (map: MapDataType) => void;
	activeMapId: string;
};

const GroupedMapList: React.FC<GroupedMapListProps> = ({ group, activeMapId, onChangeMap }) => {
	const [isOpen, setOpen] = useState(false);

	useEffect(() => {
		if (group.maps.filter((m) => m.id === activeMapId).length > 0) {
			setOpen(true);
		}
	}, [activeMapId, group]);

	if (group.group?.hide_on_sidebar === "1") return null;

	return (
		<div className={cx("map-group transition-all", { open: isOpen })}>
			<div className='map-group-title' onClick={() => setOpen(!isOpen)}>
				{t(`mapGroups.${group.group?.id}`, { defaultValue: group.group?.name })}
				<span className={cx("map-group-toggler", { open: isOpen })}>
					<FaChevronDown />
				</span>
			</div>
			<Animate height={isOpen ? "auto" : 0}>
				<div className='map-group-items'>
					{group.maps.map((mapItem) => (
						<MapItem
							active={mapItem.id === activeMapId}
							mapItem={mapItem}
							onClick={() => onChangeMap(mapItem)}
							key={mapItem.id}
						/>
					))}
				</div>
			</Animate>
		</div>
	);
};

type MapListProps = {
	maps: GroupedMap[];
	className?: string;
	onChangeMap: (map: MapDataType) => void;
	activeMapId: string;
};

const MapList: React.FC<MapListProps> = ({
	maps: groupedMaps,
	activeMapId,
	className,
	onChangeMap,
}) => {
	return (
		<div className={"map-list " + className}>
			{groupedMaps.map((row) => {
				if (row.group) {
					return (
						<GroupedMapList
							activeMapId={activeMapId}
							onChangeMap={onChangeMap}
							key={row.group.id}
							group={row}
						/>
					);
				} else {
					return row.maps.map((mapItem) => (
						<MapItem
							active={activeMapId === mapItem.id}
							mapItem={mapItem}
							key={mapItem.id}
							onClick={() => onChangeMap(mapItem)}
						/>
					));
				}
			})}
		</div>
	);
};

export default MapList;

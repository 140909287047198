import { useState } from "react";
import { WithRequiredProperty } from "../../types";
import { GroupedMap } from "../../types/MapTypes";
import cx from "classnames";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import MapsDropdownItem from "./MapsDropdownItem";

interface MapsDropdownGroupProps {
	group: WithRequiredProperty<GroupedMap, "group">;
	activeMapId: string;
	selectMap: (mapId: string) => void;
}

const MapsDropdownGroup: React.FC<MapsDropdownGroupProps> = ({ group, activeMapId, selectMap }) => {
	const [isOpen, setIsOpen] = useState(false);

	if (group.group?.hide_on_sidebar === "1") return null;

	return (
		<div className={cx({ "bg-black/30": isOpen })}>
			<div
				className="px-2 py-1 flex justify-between items-center"
				onClick={() => setIsOpen(!isOpen)}
			>
				<span>{group.group.name}</span>
				<span>{isOpen ? <FiChevronUp /> : <FiChevronDown />}</span>
			</div>
			{isOpen ? (
				<div>
					{group.maps.map((map) => (
						<MapsDropdownItem
							map={map}
							active={activeMapId === map.id}
							selectMap={selectMap}
						/>
					))}
				</div>
			) : null}
		</div>
	);
};

export default MapsDropdownGroup;

import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import './Sidebar.scss';
import { css, cx } from '@emotion/css';

type SidebarProps = {
	isOpen: boolean;
	onClose?: () => void;
	position?: 'left' | 'right';
	width?: number;
	zIndex?: number;
	className?: string;
	alwaysScroll?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({
	children,
	isOpen,
	position = 'left',
	width = 400,
	zIndex = 2000,
	className,
	alwaysScroll,
}) => {
	const getTransitionProps = () => {
		let props = {
			enter: 'transform transition duration-[400ms]',
			enterFrom: 'opacity-0 -translate-x-full',
			enterTo: 'opacity-100 translate-x-0',
			leave: 'transform duration-400 transition ease-in-out',
			leaveFrom: 'opacity-100 translate-x-0',
			leaveTo: 'opacity-0 -translate-x-full',
		};

		if (position === 'right') {
			props.enterFrom = 'opacity-0 translate-x-full';
			props.enterTo = 'opacity-100 translate-x-0';
			props.leaveFrom = 'opacity-100 translate-x-0';
			props.leaveTo = 'opacity-0 translate-x-full';
		}

		return props;
	};

	return (
		<Transition as={Fragment} show={isOpen} appear {...getTransitionProps()}>
			<div
				id={`sidebar-wrapper-${position}`}
				className={cx(
					'sidebar-wrapper',

					css({ width: width, zIndex: zIndex }),
					{
						right: position === 'right',
						left: position === 'left',
						'always-scroll': alwaysScroll,
					},
					className
				)}
			>
				{children}
			</div>
		</Transition>
	);
};

export default Sidebar;

import { useRecoilValueLoadable } from "recoil";
import { TooltipsListState } from "../store/tooltipsState";

const useTooltips = () => {
	const tooltips = useRecoilValueLoadable(TooltipsListState);

	const getTooltip = (tooltipId: string) => {
		let tips = tooltips.getValue();
		return tips.find((t) => t.id === tooltipId);
	};

	return {
		loading: tooltips.state === "loading",
		error: tooltips.state === "hasError",
		tooltips: tooltips.getValue(),
		getTooltip,
	};
};

export default useTooltips;

import { atom } from "recoil";
import { CommentErrorTypes, CommentType } from "../types/commentTypes";

export const MarkerComments = atom<CommentType[]>({
	key: "MakerComments",
	default: [],
});

export const CommentsLoading = atom<boolean>({
	key: "CommentsLoading",
	default: false,
});

export const CommentsError = atom<string | null>({
	key: "CommentsError",
	default: null,
});

export const CommentsErrorType = atom<CommentErrorTypes | null>({
	key: "CommentsErrorType",
	default: null,
});

export const IsFetchingComments = atom<Record<string, boolean>>({
	key: "IsFetching",
	default: {},
});

export const CommentsLanguageState = atom<string>({
	key: "CommentsLanguageState",
	default: "en",
});

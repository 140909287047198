import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useScreen from "use-screen";
import { TopMaps } from "../components";
import { useMaps, useSiteOptions } from "../hooks";
import { SidebarOpenState, TopNavHeightState } from "../store";

const TopMapNav: React.FC = () => {
	const { maps, setCurrentMap, currentMap } = useMaps();
	const { isMobile } = useScreen();
	const { siteOptions } = useSiteOptions();
	const isSidebarOpen = useRecoilValue(SidebarOpenState);
	const setTopNavHeight = useSetRecoilState(TopNavHeightState);

	if (
		siteOptions.state === "loading" ||
		siteOptions.state === "hasError" ||
		siteOptions.contents?.sidebar_position === "left" ||
		isMobile
	) {
		return null;
	}

	if (maps.state === "hasValue") {
		return (
			<TopMaps
				maps={maps.contents}
				activeMapId={currentMap}
				isSidebarOpen={isSidebarOpen}
				onChangeHeight={(height) => setTopNavHeight(height)}
				onChangeMap={(map) => setCurrentMap(map.id, true, true)}
			/>
		);
	}
	return null;
};

export default TopMapNav;

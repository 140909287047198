import { isArray } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { DropdownControlled, DropDownMini, Loader } from "../../components";
import { useAppDispatch, useAppSelector, useLogin } from "../../hooks";
import { getTranslations } from "../../services/api";
import { UserLanguageActions } from "../../store-redux/slices/user/userLanguageSlice";

const LanguageDropdown: React.FC<{ isMini?: boolean }> = ({ isMini = false }) => {
	const { data, isLoading, isError, isFetched } = useQuery("translations", getTranslations);
	const { i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const state = useAppSelector((state) => state.userLanguageSlice);
	const { isLoggedIn } = useLogin();

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(UserLanguageActions.fetchUserLanguage());
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (state && state.language) {
			i18n.changeLanguage(state.language);
		}
	}, [state]);

	const handleLanguageChange = (language: string) => {
		if (isLoggedIn) {
			dispatch(UserLanguageActions.saveUserLanguage(language));
		} else {
			dispatch(UserLanguageActions.setLanguage({ language }));
		}
		i18n.changeLanguage(language);
	};

	const language = useMemo(() => {
		if (state && state.language) {
			return state.language;
		}
		return "en";
	}, [state]);

	if (isLoading) return <Loader />;
	if (isError) return <div className="text-red-500">Error.</div>;

	if (isFetched && data && isArray(data) && language) {
		const current = data.find((row) => row.handle === language) as any;
		const currentValue = {
			name: current.name,
			value: current.handle,
			image: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${current.country_code}.svg`,
		};
		return (
			<>
				{!isMini ? (
					data &&
					isArray(data) &&
					data.length > 0 && (
						<DropdownControlled
							items={data.map((row) => ({
								name: row.name,
								value: row.handle,
								image: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${row.country_code}.svg`,
							}))}
							selected={currentValue}
							onChange={(item) => handleLanguageChange(item.value)}
						/>
					)
				) : (
					<DropDownMini
						items={data.map((row) => ({
							value: row.handle,
							image: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${row.country_code}.svg`,
						}))}
						onChange={(item) => handleLanguageChange(item.value)}
					/>
				)}
			</>
		);
	}
	return null;
};

export default LanguageDropdown;

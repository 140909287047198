import { css } from "@emotion/css";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { getTooltipStyles } from "../../helpers";
import { MapDataType } from "../../types/MapTypes";

interface MapsDropdownItem {
	map: MapDataType;
	active: boolean;
	selectMap: (mapId: string) => void;
}

const MapsDropdownItem: React.FC<MapsDropdownItem> = ({ map, active, selectMap }) => {
	const { t } = useTranslation();

	if (map.hide_on_sidebar === "1") {
		return null;
	}
	const getClassName = () => {
		return css`
			color: ${map.font_color} !important;
			background-color: ${map.background_color} !important;
			&:hover,
			&.active {
				color: ${map.hover_text_color} !important;
				background-color: ${map.hover_color} !important;
			}
		`;
	};

	return (
		<div
			className={cx("px-2 py-1 select-none", getClassName(), { active })}
			onClick={() => selectMap(map.id)}
			data-tip={map.tooltip}
			data-for={map.id}
		>
			{t(`maps.${map.id}`, { defaultValue: map.name })}
			<ReactTooltip
				id={map.id}
				effect="solid"
				className={cx("w-64", getTooltipStyles(map.tooltip_color, map.tooltip_background))}
				place="top"
				delayShow={1000}
			/>
		</div>
	);
};

export default MapsDropdownItem;

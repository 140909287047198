import { isArray } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Loader, UserBlock } from "../../components";
import UserProgress from "../../components/UserProgress/UserProgress";
import { useLogin } from "../../hooks";
import useMarkerToggle from "../../hooks/useMarkerToggle";
import LoginButton from "./LoginButton";

const Profile: React.FC = () => {
	const { isLoggedIn, user, logout, userProgressData, saveUsername } = useLogin();
	const { hiddenMarkers } = useMarkerToggle();

	const { t } = useTranslation();

	useEffect(() => {
		userProgressData.refetch();
	}, [hiddenMarkers]);

	return (
		<div className='text-white flex flex-col'>
			{isLoggedIn ? (
				<div>
					<UserBlock
						username={user?.username || ""}
						email={user?.email || ""}
						profileImage={user?.profileImage || ""}
						onClickLogout={logout}
						onClickSave={saveUsername}
					/>
					{userProgressData.loading ? (
						<Loader />
					) : userProgressData.error ? (
						t("error")
					) : (
						isArray(userProgressData.data) && (
							<UserProgress title={t("userProgress.title")} data={userProgressData.data} />
						)
					)}
				</div>
			) : (
				<LoginButton />
			)}
		</div>
	);
};

export default Profile;

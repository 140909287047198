import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiStar } from "react-icons/fi";
import Control from "react-leaflet-custom-control";
import { Bookmarks, ControlButton } from "../../components";
import { useBookmarks, useMaps } from "../../hooks";
import { BookmarkType } from "../../types";

const BookmarksControl: React.FC = () => {
	const [isOpen, setOpen] = useState(false);
	const { setActiveMarker, setCurrentMap } = useMaps();
	const { bookmarks, loading, error, refetch, deleteBookmark } = useBookmarks();
	const { t } = useTranslation("", { keyPrefix: "bookmarks" });

	useEffect(() => {
		if (isOpen) {
			refetch();
		}
	}, [isOpen, refetch]);

	const handleMarkerFocus = async (b: BookmarkType) => {
		setCurrentMap(b.map_id);
		setActiveMarker(b.marker_id);
	};

	const handleBookmarkDelete = (b: BookmarkType) => {
		deleteBookmark(b.id);
	};

	return (
		<>
			<Control position='topright' style={{ flexDirection: "row", alignSelf: "flex-end" }}>
				<ControlButton
					onClick={() => setOpen(!isOpen)}
					active={isOpen}
					tooltip={t("buttonTooltip")}
					tooltipPosition='left'
				>
					<FiStar />
				</ControlButton>
				<Bookmarks
					onClick={handleMarkerFocus}
					onDelete={handleBookmarkDelete}
					onClose={() => setOpen(false)}
					isOpen={isOpen}
					bookmarks={bookmarks}
					loading={loading}
					error={error}
					title={t("title")}
					noBookmarksText={t("noBookmarksAdded")}
					errorText={t("error")}
				/>
			</Control>
		</>
	);
};

export default BookmarksControl;

import React, { useState } from "react";

export type SubmitAddCommentType = {
	content: string;
	language: string;
};

type AddCommentProps = {
	title: string;
	isLoading: boolean;
	language: string;
	buttonContent: React.ReactNode;
	onSubmit: (e: SubmitAddCommentType) => Promise<boolean>;
};

const AddComment: React.FC<AddCommentProps> = (props) => {
	const { onSubmit, isLoading, buttonContent, title, language } = props;
	const [content, setContent] = useState("");

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const shouldReset = await onSubmit({ content, language });
		if (shouldReset) setContent("");
	};

	return (
		<div>
			<h2 className='mb-1 font-bold'>{title}</h2>
			<form onSubmit={handleSubmit}>
				<textarea
					rows={5}
					maxLength={250}
					className='w-full rounded bg-black/20 px-3 p-2 border-black/30 focus:outline-none ring-lime-300 focus:ring-1'
					onChange={(e) => setContent(e.target.value)}
					value={content}
				></textarea>
				<div className='flex justify-between'>
					<div>{content.length} / 250</div>
					<div className='flex space-x-1.5'>
						<button
							onClick={handleSubmit}
							className='flex items-center transition-colors bg-lime-600 px-3 py-1 rounded hover:bg-lime-700'
							disabled={isLoading}
						>
							{buttonContent}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default AddComment;

import store, { RootState } from "../store-redux/store";
import type { TypedUseSelectorHook } from "react-redux";
import { useSelector, useDispatch } from "react-redux";

export { default as useSiteOptions } from "./useSiteOptions";
export { default as useMaps } from "./useMaps";
export { default as useUpdates } from "./useUpdates";
export { default as useMediaModal } from "./useMediaModal";
export { default as useTooltips } from "./useTooltips";
export { default as useLogin } from "./useLogin";
export { default as useBookmarks } from "./useBookmarks";
export {default as useNavigation} from "./useNavigation";

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

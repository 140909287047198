import { createSlice } from "@reduxjs/toolkit";

type CategorySearchState = {
	query: string;
};

const initialState: CategorySearchState = {
	query: "",
};

export const categorySearchSlice = createSlice({
	name: "categorySearch",
	initialState,
	reducers: {
		setQuery: (state, action) => {
			state.query = action.payload;
		},
	},
});

export const CategorySearchActions = { ...categorySearchSlice.actions };

export default categorySearchSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { apiSaveUserLanguage, getUserLanguage } from "../../../services/userApi";
import { STATUS } from "../../../types";

type UserLanguageState = {
	status: STATUS;
	language: string | null;
};

const initialState: UserLanguageState = {
	status: STATUS.IDLE,
	language: "en",
};

const fetchUserLanguage = createAsyncThunk("userLanguage/fetchUserLanguage", async (_, tools) => {
	const res = await getUserLanguage();
	if (res.status === "success") {
		return res;
	} else {
		tools.rejectWithValue(res);
	}
});

const saveUserLanguage = createAsyncThunk(
	"userLanguage/saveUserLanguage",
	async (language: string, tools) => {
		const res = await apiSaveUserLanguage(language);
		if (res.status === "success") {
			return res;
		} else {
			tools.rejectWithValue(res);
		}
	}
);

const userLanguageSlice = createSlice({
	name: "userLanguage",
	initialState,
	reducers: {
		setLanguage(state, action) {
			state.language = action.payload.language;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserLanguage.fulfilled, (state, action: PayloadAction<any>) => {
			state.status = STATUS.SUCCESS;
			state.language = action.payload.language;
		});
		builder.addCase(fetchUserLanguage.pending, (state) => {
			state.status = STATUS.PENDING;
		});
		builder.addCase(fetchUserLanguage.rejected, (state) => {
			state.status = STATUS.ERROR;
		});

		builder.addCase(saveUserLanguage.fulfilled, (state, action: PayloadAction<any>) => {
			state.status = STATUS.SUCCESS;
			state.language = action.payload.language;
		});

		builder.addCase(saveUserLanguage.pending, (state) => {
			state.status = STATUS.PENDING;
		});

		builder.addCase(saveUserLanguage.rejected, (state) => {
			state.status = STATUS.ERROR;
		});
	},
});
export const UserLanguageActions = {
	...userLanguageSlice.actions,
	fetchUserLanguage,
	saveUserLanguage,
};
export default userLanguageSlice.reducer;

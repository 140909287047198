import React from "react";
import useScreen from "use-screen";
import { MapList } from "../../components";
import { useMaps, useSiteOptions } from "../../hooks";

type MapsProps = {
	className?: string;
};

const Maps: React.FC<MapsProps> = ({ className }) => {
	const { siteOptions } = useSiteOptions();
	const { maps, setCurrentMap, currentMap } = useMaps();
	const { isMobile } = useScreen();

	if (maps.state === "loading") {
		return (
			<div className='map-list animate-pulse'>
				<div className='h-10 rounded-sm bg-black/20'></div>
				<div className='h-10 rounded-sm bg-black/20'></div>
			</div>
		);
	}

	if (maps.state === "hasError") {
		return (
			<div className='flex text-white items-center justify-center h-9 bg-red-900/90 mt-3'>
				Error...
			</div>
		);
	}

	if (
		siteOptions.state !== "hasValue" ||
		(siteOptions.contents?.sidebar_position === "top" && !isMobile)
	) {
		return null;
	}

	return (
		<MapList
			className={className}
			maps={maps.contents}
			activeMapId={currentMap}
			onChangeMap={(map) => setCurrentMap(map.id, true, true)}
		/>
	);
};

export default Maps;

import React, { useState } from "react";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import Modal, { ModalProps } from "../Modal/Modal";

interface ReportModalProps extends ModalProps {
	onSubmit: (content: string) => void;
	isLoading?: boolean;
	buttonText: string;
}

const ReportModal: React.FC<ReportModalProps> = (props) => {
	const { isOpen, onClose, title, isLoading, buttonText, onSubmit: onSubmitCallback } = props;
	const [content, setContent] = useState("");

	const onSubmit = (e: any) => {
		e.preventDefault();
		onSubmitCallback(content);
		setContent("");
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} title={title}>
			<form onSubmit={onSubmit}>
				<div className='flex flex-col my-4 space-y-1.5'>
					<textarea
						className='w-full bg-black/20 rounded outline-none px-3 py-2'
						value={content}
						onChange={(e) => setContent(e.target.value)}
					/>
					<div className='flex justify-end'>
						<Button className='!bg-lime-500 hover:!bg-lime-600' disabled={isLoading}>
							{isLoading ? <Loader /> : buttonText}
						</Button>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default ReportModal;

import React from "react";
import { useTranslation } from "react-i18next";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import { toastOptions } from "../../config";
import { ActiveMarkerType } from "../../types/MapTypes";

type MarkerOverviewProps = {
	marker: ActiveMarkerType;
	copyMessage: string;
};

const MarkerOverview: React.FC<MarkerOverviewProps> = (props) => {
	const { t } = useTranslation();
	const { marker, copyMessage } = props;

	const getLink = () => {
		let url = new URL(window.location.toString());
		url.searchParams.set("marker_id", marker.id);

		return url.toString();
	};

	const copyLink = () => {
		let link = getLink();
		navigator.clipboard.writeText(link.toString());

		toast.success(copyMessage, toastOptions);
	};

	return (
		<div className='flex bg-white/10 rounded px-3 py-3 mt-3 mb-4 items-center space-x-3 '>
			<div className='w-10 mx-2'>
				<img className='w-full' src={marker.icon_image || marker.category_image} alt='icon_image' />
			</div>
			<div className='flex-1 flex flex-col justify-center items-stretch space-y-1.5'>
				<div>
					<h2 className='font-bold text-xl'>
						{t(`category.${marker.category_id}`, { defaultValue: marker.name })}
					</h2>
					<h2>{t(`markerTexts.${marker.id}`, { defaultValue: marker.icon_text })}</h2>
				</div>

				<div className='flex space-x-1.5'>
					<input
						className='border-none flex-1 bg-black/20 focus:outline-none text-base px-3 py-1 rounded'
						type='text'
						value={getLink()}
						disabled
					/>
					<button
						onClick={() => copyLink()}
						className='h-8 w-8 bg-white/20 hover:bg-lime-500 transition-colors rounded p-1.5 flex items-center justify-center'
					>
						<FiCopy />
					</button>
				</div>
			</div>
		</div>
	);
};

export default MarkerOverview;

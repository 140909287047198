import { css, cx } from "@emotion/css";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserProgressItemType } from "../../types/userTypes";

type UserProgressProps = {
	data: UserProgressItemType[] | undefined;
	title: string;
};

const UserProgress: React.FC<UserProgressProps> = (props) => {
	const { data, title } = props;

	if (data && data.length === 0) {
		return null;
	}

	return (
		<div className='mt-3 space-y-2'>
			<div className='font-bold mb-2'>{title}</div>
			{data && data.map((item) => <UserProgressItem item={item} />)}
		</div>
	);
};

const UserProgressItem: React.FC<{ item: UserProgressItemType }> = (props) => {
	const { item } = props;

	const percentage = useMemo(() => (parseInt(item.completed) / parseInt(item.total)) * 100, [item]);
	const { t } = useTranslation();

	return (
		<div className='flex flex-col space-y-3 bg-theme-900 px-2 py-3 rounded-sm'>
			<div className='flex justify-between'>
				<div className='flex flex-1'>
					<div className='font-semibold flex mr-1'>
						<img src={item.image} className='w-6 h6 mr-1' alt='' />
						<span>{t(`category.${item.category_id}`, { defaultValue: item.name })}: </span>
					</div>
					<span>
						{item.completed} / {item.total}
					</span>
				</div>
				<div className=''>{percentage.toFixed(0)} %</div>
			</div>
			<div className='flex relative'>
				<div className='h-2 flex-1 bg-white/20 rounded-lg'></div>
				<div
					className={cx(
						"h-2 absolute top-0 left-0 bg-lime-400 rounded-lg",
						css`
							width: ${percentage}%;
						`
					)}
				></div>
			</div>
		</div>
	);
};

export default UserProgress;

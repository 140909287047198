import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { getUpdates } from "../services/api";
import { UpdatesModalOpenState } from "../store/modalsState";

const useUpdates = () => {
	const updates = useQuery("updates", getUpdates, {
		enabled: false,
	});
	const [isOpen, setOpen] = useRecoilState(UpdatesModalOpenState);

	return { updates, isOpen, setOpen };
};

export default useUpdates;

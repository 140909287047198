import React, { useEffect, useMemo, useRef } from "react";
import { Polygon } from "react-leaflet";
import { useMaps } from "../../hooks";
import useNotes from "../../hooks/useNotes";
import { UserPolygon } from "../../types/userTypes";
import NotePopup from "./NotePopup";

const NotePolygones: React.FC = () => {
	const { userFeatures } = useNotes();
	const { currentMap } = useMaps();

	let notePolygones: UserPolygon[] = userFeatures.filter(
		(row) => row.type === "polygon" && row.map_id === currentMap
	) as UserPolygon[];

	return (
		<>
			{notePolygones.map((line) => {
				return <NotePolygone key={`note_polylgone_${line.id}`} polygon={line} />;
			})}
		</>
	);
};

export default NotePolygones;

type NotePolygonProps = {
	polygon: UserPolygon;
};

const NotePolygone: React.FC<NotePolygonProps> = (props) => {
	const { polygon } = props;
	const { deleteFeature, updateFeature, activeNote } = useNotes();
	const polygonRef = useRef<L.Polygon>(null);

	const geometry = useMemo(() => JSON.parse(polygon.geometry), [polygon]);

	const handleNoteChange = (note: string) => {
		updateFeature(polygon.id, note);
	};

	useEffect(() => {
		if (activeNote === polygon.id) {
			polygonRef.current?.openPopup();
		}
	}, [activeNote, polygon.id]);

	return (
		<>
			<Polygon ref={polygonRef} positions={geometry}>
				<NotePopup
					feature={polygon}
					type='polygon'
					onDelete={() => deleteFeature(polygon.id)}
					onChangeNote={handleNoteChange}
				/>
			</Polygon>
		</>
	);
};

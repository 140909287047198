import React from "react";
import { useTranslation } from "react-i18next";
import { CgShapeHexagon } from "react-icons/cg";
import Control from "react-leaflet-custom-control";
import { ControlButton } from "../../components";

type PolygonControlProps = {
	onClick: () => void;
};

const PolygonControl: React.FC<PolygonControlProps> = (props) => {
	const { onClick } = props;

	const { t } = useTranslation();

	return (
		<Control position='topleft'>
			<ControlButton onClick={onClick} tooltip={t("userFeatures.polygonTooltip")}>
				<CgShapeHexagon />
			</ControlButton>
		</Control>
	);
};

export default PolygonControl;

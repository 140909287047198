import { Transition } from "@headlessui/react";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiTrash, FiX } from "react-icons/fi";
import { BookmarkType } from "../../types";
import Loader from "../Loader/Loader";

type BookmarksProps = {
	isOpen: boolean;
	bookmarks: BookmarkType[];
	error: any;
	loading: boolean;
	title: string;
	noBookmarksText: string;
	errorText: string;
	onClose?: () => void;
	onDelete: (bookmark: BookmarkType) => void;
	onClick: (bookmark: BookmarkType) => void;
};

type BookmarksMapItemProps = {
	bookmarks: BookmarkType[];
	mapName: string;
	onDelete: (bookmark: BookmarkType) => void;
	onClick: (bookmark: BookmarkType) => void;
};

const BookmarkMapItem: React.FC<BookmarksMapItemProps> = (props) => {
	const { bookmarks, mapName, onClick, onDelete } = props;
	const { t } = useTranslation();
	return (
		<div>
			<div className='font-semibold text-base'>{mapName}</div>
			<div className='pt-2'>
				{bookmarks.map((b) => (
					<div className='flex'>
						<div
							className='flex flex-1 cursor-pointer px-2 py-2 transition-colors rounded hover:bg-black/20'
							onClick={() => onClick(b)}
						>
							<img src={b.marker_image || b.category_image} className='w-5' alt='category_image' />
							<div className='ml-2'>
								{t(`category.${b.category_id}`, { defaultValue: b.category_name })}
							</div>
						</div>
						<button
							className='w-8 flex rounded transition-colors items-center justify-center hover:bg-red-500 '
							onClick={() => onDelete(b)}
						>
							<FiTrash />
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

const Bookmarks: React.FC<BookmarksProps> = (props) => {
	const { isOpen, onClose, bookmarks, error, loading, title, noBookmarksText, errorText, ...rest } =
		props;

	const { t } = useTranslation();
	const getGrouped = () => {
		return _.groupBy(bookmarks, "map_id");
	};

	return (
		<Transition
			as={React.Fragment}
			show={isOpen}
			enter='transition ease-out duration-[200ms] origin-top-right'
			enterFrom='transform opacity-0 scale-90'
			enterTo='transform opacity-100 scale-100'
			leave='transition ease-in duration-75 origin-top-right'
			leaveFrom='transform opacity-100 scale-100'
			leaveTo='transform opacity-0 scale-95'
		>
			<div className='absolute min-w-[350px] right-full top-0 mx-2'>
				<div className='rounded bg-theme-800 px-4 py-1 text-white'>
					<h3 className='py-2 text-base font-semibold flex items-center justify-between'>
						<span>{title}</span>
						<button
							className='rounded-full p-0.5 bg-white/20 transition-colors hover:bg-red-500'
							onClick={onClose}
						>
							<FiX />
						</button>
					</h3>
					{/* World  */}
					<div className='pb-3 space-y-3'>
						{error && <div>{errorText}</div>}
						{loading && <Loader />}
						{Object.values(getGrouped()).map((bookmarksList) => {
							return (
								<BookmarkMapItem
									{...rest}
									bookmarks={bookmarksList}
									mapName={t(`maps.${bookmarksList[0].map_id}`, {
										defaultValue: bookmarksList[0].map_name,
									})}
								/>
							);
						})}
						{bookmarks.length === 0 && !loading && <div>{noBookmarksText}</div>}
					</div>
				</div>
			</div>
		</Transition>
	);
};

export default Bookmarks;

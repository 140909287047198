import React, { useEffect } from "react";
import { ControlButton, Loader, Sidebar } from "../../components";
import Control from "react-leaflet-custom-control";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useMaps } from "../../hooks";
import { Portal } from "@headlessui/react";
import { useRecoilState } from "recoil";
import { RightSidebarOpenState } from "../../store";
import { css } from "@emotion/css";
import { getRightSidebarOptions } from "../../services/api";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { decodeHTMLEntities } from "../../helpers";

const RightSidebar: React.FC = () => {
	const [isOpen, setOpen] = useRecoilState(RightSidebarOpenState);
	const rightSidebarContent = useQuery("rightSidebarContent", getRightSidebarOptions, {
		enabled: false,
	});
	const { currentMapData } = useMaps();
	const data = currentMapData.getValue();

	const { t } = useTranslation();

	useEffect(() => {
		if (data) {
			if (data.show_right_sidebar === "1") {
				rightSidebarContent.refetch();
				setOpen(true);
			} else {
				setOpen(false);
			}
		}
	}, [currentMapData, data, setOpen]);

	if (data && data.show_right_sidebar === "0") {
		return null;
	}

	if (rightSidebarContent.isError) {
		return null;
	}

	return (
		<>
			<Control position="topright" style={{ order: -9999 }}>
				<ControlButton
					onClick={() => setOpen(!isOpen)}
					tooltip={t("controls.tips.toggleSidebar")}
					tooltipPosition="left"
				>
					{isOpen ? <FaChevronRight /> : <FaChevronLeft />}
				</ControlButton>
			</Control>
			<Portal>
				<Sidebar
					isOpen={isOpen}
					position="right"
					width={300}
					zIndex={1000}
					className={css`
						padding-top: 100px;
					`}
				>
					{rightSidebarContent.isIdle && <Loader />}
					{rightSidebarContent.isLoading && <Loader />}
					{rightSidebarContent.isError && <div className="text-red-500">Error!</div>}
					{rightSidebarContent.isFetched && (
						<div
							className="scroll-bar no-styles text-white"
							dangerouslySetInnerHTML={{
								__html: decodeHTMLEntities(
									rightSidebarContent.data?.options.sidebar_content || ""
								),
							}}
						></div>
					)}
				</Sidebar>
			</Portal>
		</>
	);
};

export default RightSidebar;

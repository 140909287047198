import React from "react";
import { MediaModal } from "../../components";
import { useMediaModal } from "../../hooks";

const MediaModalView: React.FC = () => {
	const { isOpen, closeModal, media, type } = useMediaModal();

	return (
		<MediaModal isOpen={isOpen} onClose={closeModal}>
			{media && type === "image" && <img src={media} alt='popup' />}
			{media && type === "iframe" && (
				<iframe title='Modal Iframe' src={media} width='100%' height='400px' allowFullScreen />
			)}
		</MediaModal>
	);
};

export default MediaModalView;

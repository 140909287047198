import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAppSelector } from "../../hooks";
import { CurrentMapDataState } from "../../store";
import { MapCategoryType } from "../../types/MapTypes";
import CategoryItem from "../CategoryItem/CategoryItem";

const MobileSearchResults = () => {
	const searchQuery = useAppSelector((state) => state.categorySearch.query);
	const mapData = useRecoilValue(CurrentMapDataState);
	const { t } = useTranslation();

	const mapCategories = useMemo(() => {
		let categories: MapCategoryType[] = [];

		// Get all categories from all groups
		// Check if mapData contains a groups property
		if (mapData?.groups) {
			// If so, iterate through each group...
			mapData.groups.forEach((group) => {
				// ...and check if it contains a categories property
				if (group.categories) {
					// If so, add the categories to the categories array
					categories = categories.concat(group.categories);
				}
			});
		}

		// Remove duplicate categories
		categories = categories.filter((category, index) => {
			// The function is used to filter out the duplicate categories from the categories array
			return categories.findIndex((c) => c.id === category.id) === index;
		});

		return categories;
	}, [mapData]);

	const filteredCategories = useMemo(() => {
		// Filter the categories based on the search query
		let categories: MapCategoryType[] = [];

		// Check if the search query is not empty
		if (searchQuery) {
			// If so, iterate through each category...
			mapCategories.forEach((category) => {
				// ...and check if the category name contains the search query
				if (
					t(`category.${category.id}`, { defaultValue: category.name })
						.toLowerCase()
						.includes(searchQuery.toLowerCase())
				) {
					categories.push(category);
				}
			});
		}

		return categories;
	}, [mapCategories, searchQuery]);

	return (
		<div className="grid grid-cols-2 gap-x-2">
			{filteredCategories.map((category) => (
				<CategoryItem item={category} />
			))}
		</div>
	);
};

export default MobileSearchResults;

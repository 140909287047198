import { useDebugValue, useEffect } from "react";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import {
	ActiveMarkerState,
	CurrentMapDataState,
	CurrentMapState,
	HiddenCategoriesState,
	MapNavigationHistoryState,
	MapsListState,
} from "../store/mapState";
import _ from "lodash";
import { MapGroupType, MapHistoryType } from "../types/MapTypes";
import { v4 as uuid } from "uuid";
import { useQuery } from "react-query";
import { getUserVisited } from "../services/userApi";
import useLogin from "./useLogin";

const useMaps = () => {
	useDebugValue("useMaps");
	const maps = useRecoilValueLoadable(MapsListState);
	const { token } = useLogin();
	const currentMapData = useRecoilValueLoadable(CurrentMapDataState);
	const [currentMap, _setCurrentMap] = useRecoilState(CurrentMapState);

	const [hiddenCategories, setHiddenCategories] = useRecoilState(HiddenCategoriesState);
	const [activeMarker, _setActiveMarker] = useRecoilState(ActiveMarkerState);
	const [mapHistory, setMapHistory] = useRecoilState(MapNavigationHistoryState);
	const { data, refetch, isFetching } = useQuery(
		`userVisited.${token}`,
		() => getUserVisited(token || ""),
		{
			enabled: false,
		}
	);

	useEffect(() => {
		let url = new URL(window.location.toString());
		if (url.searchParams.get("marker_id")) setActiveMarker(url.searchParams.get("marker_id"));
	}, []);

	// Set current map to first map;
	useEffect(() => {
		if (maps.state === "hasValue" && !currentMap) {
			setDefaultMap();
		}
	}, [maps, currentMap]);

	const setDefaultMap = () => {
		if (currentMap) return;

		let urlMap = getUrlMap();
		let getLink = getUrlShareLink();
		if (urlMap) {
			return setCurrentMap(urlMap, false);
		}

		if (maps.contents) {
			const defaultMap = getDefaultMap();
			setCurrentMap(defaultMap, getLink === null);
		}
	};

	const getDefaultMap = () => {
		if (maps.contents) {
			let firstMap = maps.contents[0].maps[0].id;
			return firstMap;
		}
	};

	// Map
	const setCurrentMap = (mapId: string, reset = true, history = false) => {
		const url = new URL(window.location.toString());
		if (reset) {
			url.search = "";
			setActiveMarker(null);
		}

		if (history) {
			pushToHistory({ id: uuid(), mapId: currentMap });
		}

		url.searchParams.set("map", mapId);
		window.history.pushState({}, "", url.toString());
		_setCurrentMap(mapId);
	};

	const getUrlMap = () => {
		const url = new URL(window.location.toString());
		return url.searchParams.get("map");
	};

	const getUrlShareLink = () => {
		const url = new URL(window.location.toString());
		return url.searchParams.get("l");
	};

	// Push to map history
	const pushToHistory = (h: MapHistoryType) => {
		setMapHistory([...mapHistory, h]);
	};

	// Go back one step
	const goBack = () => {
		if (mapHistory.length) {
			let lastItem = mapHistory[mapHistory.length - 1];
			setMapHistory(_.without(mapHistory, lastItem));
			setCurrentMap(lastItem.mapId);
			if (lastItem.markerId) {
				setActiveMarker(lastItem.markerId);
			}
		} else {
			setCurrentMap(getDefaultMap());
		}
	};

	// Marker Groups
	const toggleGroup = (group: MapGroupType) => {
		let categoriesIds = group.categories?.map((cat) => cat.id);
		let hiddenOfGroup = _.intersection(hiddenCategories, categoriesIds);

		if (!categoriesIds) return;

		if (categoriesIds.length > hiddenOfGroup.length) {
			setHiddenCategories([..._.without(hiddenCategories, ...hiddenOfGroup), ...categoriesIds]);
		} else {
			setHiddenCategories([..._.without(hiddenCategories, ...categoriesIds)]);
		}
	};

	// Categories
	const toggleCategory = (categoryId: string) => {
		if (isCategoryHidden(categoryId)) {
			setHiddenCategories(_.without(hiddenCategories, categoryId));
			return;
		}
		setHiddenCategories([...hiddenCategories, categoryId]);
	};

	const isCategoryHidden = (categoryId: string) => {
		return _.includes(hiddenCategories, categoryId);
	};

	const toggleAll = () => {
		if (currentMapData.state === "hasValue" && currentMapData.contents) {
			let groups = currentMapData.contents.groups;
			let catIds: any = groups.map((g) => g.categories);
			catIds = _.flatten(catIds).map((cat: any) => cat.id);

			if (hiddenCategories.length === catIds.length) {
				setHiddenCategories([]);
			} else {
				setHiddenCategories(catIds);
			}
		}
	};

	const setActiveMarker = (markerId: string | null, resetUrl: boolean = false) => {
		_setActiveMarker(markerId);
		if (resetUrl) {
			let url = new URL(window.location.toString());
			url.searchParams.delete("marker_id");
			window.history.pushState({}, "", url.toString());
		}
	};

	return {
		maps,
		getDefaultMap,

		currentMap,
		setCurrentMap,
		currentMapData,

		toggleAll,

		hiddenCategories,
		toggleCategory,
		isCategoryHidden,
		setHiddenCategories,

		toggleGroup,

		activeMarker,
		setActiveMarker,

		pushToHistory,
		goBack,
	};
};

export default useMaps;

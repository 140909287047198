import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addUserVisited, deleteUserVisited, getUserVisited } from "../../../services/userApi";
import { STATUS } from "../../../types";
import { RootState } from "../../store";

type StateType = {
	status: STATUS;
	list: string[];
};

const initialState: StateType = {
	status: STATUS.IDLE,
	list: [],
};

const syncVisitedMarkers = createAsyncThunk(
	"map/visitedMarkers/add",
	async (payload: { token: string; markers: string[]; action: "add" | "remove" }, thunkApi) => {
		const { token, markers, action } = payload;
		let res = {};

		if (action === "add") {
			res = await addUserVisited(token, markers);
		}

		if (action === "remove") {
			res = await deleteUserVisited(token, markers);
		}

		return { markers, action, ...(res as any) };
	}
);

const fetchVisitedMarkers = createAsyncThunk(
	"map/visitedMarkers/fetch",
	async (token: string, thunkApi) => {
		let res = await getUserVisited(token);
		return { res };
	}
);

const visitedMarkersSlice = createSlice({
	name: "map/visitedMarkers",
	initialState,
	reducers: {
		test(state) {
			state.status = STATUS.PENDING;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(syncVisitedMarkers.pending, (state) => {
			state.status = STATUS.PENDING;
		});
		builder.addCase(syncVisitedMarkers.rejected, (state) => {
			state.status = STATUS.ERROR;
		});
		builder.addCase(syncVisitedMarkers.fulfilled, (state, action) => {
			let res = action.payload;

			if (res.status === "success") {
				state.status = STATUS.SUCCESS;
				if (res.action === "add") {
					state.list.push(...res.markers);
				} else {
					state.list = state.list.filter((marker) => !res.markers.includes(marker));
				}
			}
		});

		builder.addCase(fetchVisitedMarkers.pending, (state) => {
			state.status = STATUS.PENDING;
		});
		builder.addCase(fetchVisitedMarkers.rejected, (state) => {
			state.status = STATUS.ERROR;
		});
		builder.addCase(fetchVisitedMarkers.fulfilled, (state, action) => {
			let res = action.payload;
			state.status = STATUS.SUCCESS;
			state.list = res.res.visitedMarkers.map((marker) => marker.marker_id);
		});
	},
});

export const VisitedMarkersActions = {
	...visitedMarkersSlice.actions,
	syncVisitedMarkers,
	fetchVisitedMarkers,
};
export default visitedMarkersSlice.reducer;

import { Portal, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import useScreen from "use-screen";
import cs from "classnames";
import "./Modal.scss";
import { FiX } from "react-icons/fi";

export interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	title?: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
	const { isMobile } = useScreen();

	return (
		<Portal>
			<Transition as={Fragment} show={isOpen}>
				<div className={cs("modal", { isMobile: isMobile })}>
					<Transition.Child
						as={Fragment}
						enter='transition duration-[500ms]'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='transition duration-[500ms]'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='modal-overlay' onClick={onClose}></div>
					</Transition.Child>
					<div className='modal-content-wrapper'>
						<Transition.Child
							as={Fragment}
							enter='transform transition-all duration-[500ms]'
							enterFrom='opacity-0 scale-75 translate-y-20'
							enterTo='opacity-100 scale-100 translate-y-0'
							leave='transition-all duration-[200ms]'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-75'
						>
							<div className='modal-content'>
								<div className='modal-header'>
									<div className='modal-title'>{title}</div>
									<span className='modal-close-btn' onClick={onClose}>
										<FiX />
									</span>
								</div>
								{children}
							</div>
						</Transition.Child>
					</div>
				</div>
			</Transition>
		</Portal>
	);
};

export default Modal;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Clock as ClockC, Loader } from "../../components";
import { getTimezones } from "../../services/api";

const Clock: React.FC<{ isMini?: boolean }> = ({ isMini = false }) => {
	const { isLoading, error, data, refetch } = useQuery("timezones", getTimezones, {
		enabled: false,
	});
	const { t } = useTranslation();

	useEffect(() => {
		refetch();
	}, []);

	if (error) {
		return t("error");
	}

	if (isLoading) {
		return <Loader />;
	}

	if (!data) {
		return t("error");
	}

	if (data.length === 0) {
		return null;
	}

	return (
		<ClockC
			timezones={data}
			localTimeTitle={t("localTimeTitle")}
			serverTimeTitle={t("serverTimeTitle")}
			isMini={isMini}
		/>
	);
};

export default Clock;

import { LatLng } from "leaflet";
import _, { isArray } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { v4 as uuid } from "uuid";
import { toastOptions } from "../config";
import {
	addUserFeature,
	deleteUserFeature,
	getUserFeatures,
	updateUserFeature,
} from "../services/userApi";
import { UserFeaturesState, UserTokenIdState } from "../store";
import { NotesActiveState } from "../store/mapState";
import { UserFeature } from "../types/userTypes";
import useMaps from "./useMaps";

const useNotes = () => {
	const [activeNote, setActiveNote] = useRecoilState(NotesActiveState);
	const [userFeatures, setUserFeatures] = useRecoilState(UserFeaturesState);
	const token = useRecoilValue(UserTokenIdState);
	const { currentMap, currentMapData } = useMaps();
	const { isLoading, isError, data } = useQuery("userFeatures" + token, () =>
		getUserFeatures(token || "")
	);
	const { t } = useTranslation();

	useEffect(() => {
		if (!isError && !isLoading && data && userFeatures.length === 0) {
			isArray(data) && setUserFeatures(data);
		}
	}, [isError, isLoading, data]);

	const updateFeature = async (featureId: string, notes: string, geometry?: string) => {
		let feature = _.find(userFeatures, { id: featureId });
		if (feature?.geometry === geometry && feature?.notes === notes) return;
		if (!token || !feature) return;
		const updated = await updateUserFeature(token, featureId, {
			notes: notes,
			geometry: geometry || feature.geometry,
		});
		if (updated.status === "error") {
			alert("showing error");
			return toast.error(t("userFeatures.syncError"), toastOptions);
		}
		setUserFeatures([..._.without(userFeatures, feature), updated.feature]);
	};

	const deleteFeature = async (featureId: string) => {
		let feature = _.find(userFeatures, { id: featureId });
		if (!token || !feature) return;
		const deleted = await deleteUserFeature(token, featureId);

		if (deleted.status === "error") {
			toast.error(t("userFeatures.syncError"), toastOptions);
		} else {
			setUserFeatures(_.without(userFeatures, feature));
		}
	};

	const addNoteMarker = async (latLng: LatLng) => {
		if (!token) return;
		let id = uuid();
		let markerData: UserFeature = {
			id: id,
			map_id: currentMap,
			type: "marker",
			notes: "",
			geometry: JSON.stringify(latLng),
			tiles_coordinates: currentMapData.getValue()?.tiles_mode || "1",
		};

		const added = await addUserFeature(token, {
			..._.omit(markerData, ["id", "tiles_coordinates"]),
			tiles_coordinates: currentMapData.getValue()?.tiles_mode === "1",
		});

		if (added.status === "error") {
			toast.error(t("userFeatures.syncError"), toastOptions);
		} else {
			setActiveNote(added.feature.id);
			setUserFeatures([...userFeatures, added.feature]);
		}
	};

	const addNotePolyline = async (latLng: any) => {
		if (!token) return;
		let id = uuid();
		let polylineData: UserFeature = {
			id: id,
			map_id: currentMap,
			type: "polyline",
			notes: "",
			geometry: JSON.stringify(latLng),
			tiles_coordinates: currentMapData.getValue()?.tiles_mode || "1",
		};

		const added = await addUserFeature(token, {
			..._.omit(polylineData, ["id", "tiles_coordinates"]),
			tiles_coordinates: currentMapData.getValue()?.tiles_mode === "1",
		});

		if (added.status === "error") {
			toast.error(t("userFeatures.syncError"), toastOptions);
		} else {
			setActiveNote(added.feature.id);
			setUserFeatures([...userFeatures, added.feature]);
		}
	};

	const addNotePolygon = async (latLng: any) => {
		if (!token) return;
		let id = uuid();
		let polylineData: UserFeature = {
			id: id,
			map_id: currentMap,
			type: "polygon",
			notes: "",
			geometry: JSON.stringify(latLng),
			tiles_coordinates: currentMapData.getValue()?.tiles_mode || "1",
		};

		const added = await addUserFeature(token, {
			..._.omit(polylineData, ["id", "tiles_coordinates"]),
			tiles_coordinates: currentMapData.getValue()?.tiles_mode === "1",
		});

		if (added.status === "error") {
			toast.error(t("userFeatures.syncError"), toastOptions);
		} else {
			setActiveNote(added.feature.id);
			setUserFeatures([...userFeatures, added.feature]);
		}
	};

	return {
		addNoteMarker,
		addNotePolyline,
		addNotePolygon,
		activeNote,
		setActiveNote,
		currentMap,
		deleteFeature,
		updateFeature,
		userFeatures,
	};
};

export default useNotes;

import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { FiSearch, FiX } from "react-icons/fi";
import { useDebounce } from "use-debounce";
import { useAppDispatch } from "../../hooks";
import { CategorySearchActions } from "../../store-redux/slices/categorySearch/categorySearchSlice";

interface SearchBoxProps extends React.HTMLAttributes<HTMLDivElement> {}

const MobileSearchBox: React.FC<SearchBoxProps> = ({ className, ...restProps }) => {
	const [query, setQuery] = useState("");
	const [debounceFilter, debounceControl] = useDebounce(query, 500);
	const inputRef = useRef<HTMLInputElement>(null);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(CategorySearchActions.setQuery(query));
	}, [debounceFilter]);

	const handleClear = () => {
		setQuery("");
		inputRef.current?.focus();
		debounceControl.flush();
	};

	const handleAction = () => {
		if (query.length > 0) {
			handleClear();
		} else {
			inputRef.current?.focus();
		}
	};

	return (
		<div
			className={cx(
				"bg-theme-800 py-2 px-2.5 text-white text-base rounded-sm flex space-x-1 items-center",
				className
			)}
		>
			<input
				type="text"
				className="w-full bg-transparent outline-none"
				onChange={(e) => setQuery(e.target.value)}
				value={query}
				ref={inputRef}
			/>
			<span
				className="hover:bg-black/20 transition-all cursor-pointer p-1 rounded-full"
				onClick={handleAction}
			>
				{query.length > 0 ? <FiX /> : <FiSearch />}
			</span>
		</div>
	);
};

export default MobileSearchBox;

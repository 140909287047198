import { useRecoilValueLoadable } from "recoil";
import { SiteOptionsState } from "../store/siteOptionsState";

const useSiteOptions = () => {
	const siteOptions = useRecoilValueLoadable(SiteOptionsState);

	return { siteOptions };
};

export default useSiteOptions;

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { toastOptions } from "../config";
import {
	addComment as addCommentMain,
	getComments,
	deleteComment as deleteCommentMain,
	voteComment,
	reportComment,
} from "../services/commentsApi";
import {
	ClickedMarkerState,
	CommentsError,
	CommentsErrorType,
	CommentsLanguageState,
	MarkerComments,
	UserTokenIdState,
} from "../store";

const useComments = () => {
	const [comments, setComments] = useRecoilState(MarkerComments);
	const [errorMessage, setError] = useRecoilState(CommentsError);
	const [errorType, setErrorType] = useRecoilState(CommentsErrorType);
	const clickedMarker = useRecoilValue(ClickedMarkerState);
	const commentLanguage = useRecoilValue(CommentsLanguageState);
	const { data, isLoading, refetch } = useQuery(
		`getcomments_${clickedMarker?.id}_${commentLanguage}`,
		() => getComments(clickedMarker?.id || "", commentLanguage)
	);
	const token = useRecoilValue(UserTokenIdState);
	const { t } = useTranslation();

	useEffect(() => {
		reset();
		if (data) {
			if (data.status === "success") setComments(data.comments);
			if (data.status === "error") {
				setComments([]);
				setError(data.message);
				data.errorType && setErrorType(data.errorType);
			}
		}
	}, [data]);

	const reset = () => {
		setComments([]);
		setError(null);
		setErrorType(null);
	};

	const addComment = async (content: string) => {
		if (token && clickedMarker) {
			const res = await addCommentMain(token, {
				content,
				language: commentLanguage,
				marker_id: clickedMarker.id,
			});
			return res;
		}
		return false;
	};

	const deleteComment = async (commentId: string) => {
		if (token) {
			const res = await deleteCommentMain(token, commentId);
			return res;
		}
		return false;
	};

	const vote = async (commentId: string, vote: "1" | "-1") => {
		if (!token) {
			toast.error(t("loginError"));
			return;
		}

		let voted = await voteComment(token, commentId, vote);

		if (voted.status === "success") {
			refetch();
		} else {
			if (voted.errorType === "user_banned") {
				toast.error(t("comments.voteBanned"), toastOptions);
			} else {
				toast.error(t("comments.voteError"), toastOptions);
			}
		}
	};

	const report = async (commentId: string, content: string) => {
		if (content === "" || !token) {
			return;
		}

		toast.promise(
			() => reportComment(token, commentId, content),
			{
				pending: t("comments.reporting"),
				success: t("comments.reported"),
				error: t("comments.reportingError"),
			},
			toastOptions
		);
	};

	return {
		comments,
		error: errorMessage,
		errorType,
		loading: isLoading,
		clickedMarker,
		addComment,
		deleteComment,
		refetchComments: refetch,
		vote,
		report,
	};
};

export default useComments;
